import styles from "./IntroducePage.module.css";
import SubTitle from "../components/SubTitle";
import Map from "../components/Map";
import { NavLink, useParams } from "react-router-dom";
import Location from "../components/Location";
import SubTel from "../components/SubTel";
import { useState, useEffect } from "react";
import History from "../components/History";
import orgaImg from "../assets/orga.png";
import { getOrganization } from "../Api";
import { getDirections } from "../Api";

function IntroducePage() {
  const name = "센터소개";
  const value =
    "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다.";
  const menu = "센터소개";

  const [submenu, setSubMenu] = useState("인사말");
  const [filesUrl, setFiles] = useState("");
  const no = useParams().no;

  const [toggle, setToggle] = useState(1);

  const style = {};

  let [address, setAddress] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [subway, setSubway] = useState([]);
  let [bus, setBus] = useState([]);

  if (no === "1") {
    style["height"] = "750px";
  } else if (no === "2") {
    style["height"] = "1150px";
  } else if (no === "3") {
    style["height"] = "700px";
  } else if (no === "4") {
    style["height"] = "850px";
  }

  useEffect(() => {
    if (no === "1") {
      setSubMenu("인사말");
      setToggle(1);
    } else if (no === "2") {
      setSubMenu("연혁");
      setToggle(2);
    } else if (no === "3") {
      setSubMenu("조직안내");
      setToggle(3);
      getOrganization()
        .then((response) => {
          const files = response.map((item) => item.files);
          setFiles(files);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (no === "4") {
      setSubMenu("오시는길");
      setToggle(4);
      getDirections()
        .then((response) => {
          setAddress(response.address);
          setPhoneNumber(response.phoneNumber);
          setSubway(response.subway || []);
          setBus(response.bus || []);
          //console.log("처음에 set Address", response.address);
        })
        .catch((error) => {
          console.log("캐치 에러 입니다.");
          console.log(error);
        });
    }
  }, [no]);
  const linked = "/introduce/1";
  const activeStyle = {
    color: "#E5857C",
    backgroundColor: "#FBF1F0",
  };

  return (
    <div className={styles.Intro}>
      <div className={styles.left}>
        <SubTitle name={name} value={value} />

        <ol className={styles.depth2} id="0">
          <li>
            <NavLink
              to="/introduce/1"
              id={styles.menu}
              style={({ isActive }) => (no === "1" ? activeStyle : undefined)}
            >
              인사말
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/introduce/2"
              id={styles.menu}
              style={({ isActive }) => (no === "2" ? activeStyle : undefined)}
            >
              연혁
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/introduce/3"
              id={styles.menu}
              style={({ isActive }) => (no === "3" ? activeStyle : undefined)}
            >
              조직안내
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/introduce/4"
              id={styles.menu}
              style={({ isActive }) => (no === "4" ? activeStyle : undefined)}
            >
              오시는길
            </NavLink>
          </li>
        </ol>

        <SubTel height="4" />
      </div>

      <div id={styles.right} style={style}>
        <Location menu={menu} submenu={submenu} link={linked} />
        <h2>{submenu}</h2>
        <div className={styles.Container}>
          {toggle === 1 && (
            <div className={styles.one}>
              <div className={styles.two}>
                <h1>
                  중구행복돌봄센터 홈페이지를 <br></br>방문해 주셔서 감사합니다!
                </h1>
                <p>
                  중구행복돌봄센터는 서울중구지역자활센터에서 2005년
                  가사간병사업을
                </p>
                <p>
                  시작으로 사회서비스사업을 개시하여
                  노인돌봄종합서비스,장애인활동지원,
                </p>
                <p>재가장기요양(방문요양) 등의 서비스를 제공해 왔습니다.</p>
                <p className={styles.p2}>
                  운영법인 사회적기업 에스이임파워는 사회적경제 (Social
                  Economy)에
                </p>
                <p>
                  속한 모든 조직의 역량강화 (Empower)에 기여하기 위한 사명을
                  갖고
                </p>
                <p>
                  설립된 사회적협동조합입니다. ‘사회적기업가 육성사업’,
                  ‘디지털사회혁신’,
                </p>
                <p>
                  ‘사회주택’, ‘지역자활센터 운영’ 등의 다양한 사회혁신 사업을
                  하고 있으며,
                </p>
                <p>
                  돌봄사회서비스를 통한 사회적 책임을 강화하기 위하여 2022년 1월
                </p>
                <p>
                  서울중구지역자활센터에서 중구행복돌봄센터를 법인의 지점으로
                </p>
                <p>분리하였습니다.</p>
              </div>
              <div className={styles.three}>
                <p>
                  중구행복돌봄센터는 장애인활동지원사업, 가사간병방문지원사업,
                  재가장기요양(방문요양), 돌봄SOS 등
                </p>
                <p>
                  돌봄이 필요한 이웃을 지원하는 돌봄사회서비스를 제공하고
                  있습니다.
                </p>
                <p>
                  돌봄 노동자들과 돌봄의 손길이 필요한 이웃들과 함께하며 삶의
                  질을 높이고,
                </p>
                <p>행복한 삶을 영위하는 작은 희망이 되겠습니다. </p>
              </div>
            </div>
          )}
          {toggle === 2 && <History />}

          {toggle === 3 && <img src={filesUrl[1]} alt="조직안내"></img>}

          {toggle === 4 && (
            <div>
              <Map address={address} />
              <ol className={styles.list}>
                <li className={styles.list2}>
                  <h1>주소</h1>
                  <ol>
                    <li className={styles.addr}>{address}</li>
                    <li className={styles.addr}>TEL : {phoneNumber}</li>
                  </ol>
                </li>
                <li className={styles.list2}>
                  <h1>대중교통</h1>
                  <ol>
                    <li className={styles.list3}>
                      <p>지하철</p>
                      <ol>
                        {subway.map((item, index) => (
                          <li key={index}>- &nbsp; &nbsp;{item}</li>
                        ))}
                      </ol>
                    </li>
                    <li className={styles.list3}>
                      <p>버스</p>
                      <ol>
                        {bus.map((item, index) => (
                          <li key={index}>- &nbsp; &nbsp;{item}</li>
                        ))}
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default IntroducePage;
