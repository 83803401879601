import { Link } from "react-router-dom";
import styles from "./Quick.module.css";
import bannerImg from "../assets/service.png";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function Quick() {
  const location = useLocation();
  const element = useRef(null);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    if (location.pathname === "/") {
      element.current.style.top = "680px";
      setDisplay(true);
    } else if (location.pathname === "/care/5") {
      setDisplay(false);
    } else {
      setDisplay(true);
      element.current.style.top = "220px";
    }
  }, [location.pathname]);

  return (
    <div className={styles.quick} ref={element}>
      {display && (
        <div className={styles.q_quick}>
          <div className={styles.q_q_menu}>
            <ul>
              <li>
                <Link to="/care/5">
                  <img src={bannerImg} alt="서비스 바로가기"></img>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Quick;
