import SubTitle from "../components/SubTitle";
import Location from "../components/Location";
import SubTel from "../components/SubTel";
import styles from "./SiteMap.module.css";
import { Link } from "react-router-dom";

function SiteMap() {
  const name = "사이트맵";
  const value =
    "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다.";
  const menu = "센터소개";
  const submenu = "사이트맵";
  const linked = "/introduce/1";

  return (
    <div className={styles.SiteMap}>
      <div className={styles.left}>
        <SubTitle name={name} value={value} />
        <SubTel height="0" />
      </div>

      <div id={styles.right}>
        <Location menu={menu} submenu={submenu} link={linked} />
        <h2>{submenu}</h2>

        <div className={styles.Container}>
          <h2 className={styles.hidden}>메인메뉴</h2>
          <nav id={styles.navigation}>
            <ol className={styles.depth1} id="2">
              <li className={styles.bd_line_left} id="0">
                <Link to="/introduce/1" id={styles.menu}>
                  센터소개
                </Link>

                <ol className={styles.depth2} id="0">
                  <li>
                    <Link to="/introduce/1" id="0">
                      인사말
                    </Link>
                  </li>
                  <li>
                    <Link to="/introduce/2" id="0">
                      연혁
                    </Link>
                  </li>
                  <li>
                    <Link to="/introduce/3" id="0">
                      조직안내
                    </Link>
                  </li>
                  <li>
                    <Link to="/introduce/4" id="0">
                      오시는길
                    </Link>
                  </li>
                </ol>
              </li>
              <li className={styles.m_bg} id="1">
                <Link to="/care/1" id={styles.menu}>
                  돌봄서비스
                </Link>

                <ol className={styles.depth2} id="1">
                  <li>
                    <Link to="/care/1" id="1">
                      장애인활동지원서비스
                    </Link>
                  </li>
                  <li>
                    <Link to="/care/2" id="1">
                      노인장기요양서비스
                    </Link>
                  </li>
                  <li>
                    <Link to="/care/3" id="1">
                      가사간병방문지원서비스
                    </Link>
                  </li>
                  <li>
                    <Link to="/care/4" id="1">
                      돌봄SOS서비스
                    </Link>
                  </li>
                  <li>
                    <Link to="/care/5" id="1">
                      서비스 상담 문의
                    </Link>
                  </li>
                </ol>
              </li>
              <li className={styles.m_bg} id="2">
                <Link to="/notice" id={styles.menu}>
                  공지사항
                </Link>
                <ol className={styles.depth2} id="2"></ol>
              </li>

              <li className={styles.m_bg} id="3">
                <Link to="/data" id={styles.menu}>
                  자료실
                </Link>

                <ol className={styles.depth2} id="3">
                  <li>
                    <Link to="/data" id="3">
                      일반자료실
                    </Link>
                  </li>
                  <li>
                    <Link to="/photo" id="3">
                      포토갤러리
                    </Link>
                  </li>
                </ol>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default SiteMap;
