import React, { useLayoutEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Map.module.css";

// window 객체에서 kakao를 추출합니다.
const { kakao } = window;

const Map = ({ address }) => {
  // 로딩 상태를 관리하는 state를 생성합니다.
  const [isLoading, setIsLoading] = useState(false);
  // 지도를 렌더링 할 DOM 엘리먼트를 참조하는 ref를 생성합니다.
  const container = useRef(null);
  //console.log("받아온 주소  1: ", address);

  // useLayoutEffect를 사용하여 주소가 변경될 때마다 실행되는 코드를 작성합니다.
  useLayoutEffect(() => {
    // 주소가 없거나 빈 문자열이면 에러 메시지를 출력하고 반환합니다.
    if (!address || address.trim() === "") {
      console.log("주소가 제공되지 않았습니다.");
      return;
    }
    // 주소가 제공되면 로딩 상태를 true로 변경합니다.
    setIsLoading(true);

    // 우편번호와 '(우)'를 제거합니다.
    const cleanedAddress = address.replace(/\(우\)|\d{5}/g, "").trim();

    // container ref에서 current를 가져옵니다.
    const containerElement = container.current;

    // 카카오 지도 서비스의 Geocoder 객체를 생성합니다.
    const geocoder = new kakao.maps.services.Geocoder();

    // geocoder를 사용하여 주소를 검색합니다.
    geocoder.addressSearch(cleanedAddress, (result, status) => {
      // 검색 결과가 정상적으로 반환되면 지도를 생성하고 마커를 표시합니다.
      if (status === kakao.maps.services.Status.OK) {
        const { y, x } = result[0];

        // 지도 옵션을 설정합니다.
        const options = {
          center: new kakao.maps.LatLng(y, x),
          level: 2,
        };

        // 지도 객체를 생성하고 containerElement에 렌더링합니다.
        const map = new kakao.maps.Map(containerElement, options);

        // 마커의 위치를 설정합니다.
        const markerPosition = new kakao.maps.LatLng(y, x);
        //console.log("makerPosition : ", markerPosition);
        // 마커를 생성하고 지도에 표시합니다.
        const marker = new kakao.maps.Marker({ position: markerPosition });
        marker.setMap(map);

        // 정보창을 생성하고 지도에 표시합니다.
        var infowindow = new kakao.maps.InfoWindow({
          content: `<div style="width:150px;text-align:center;padding:px 0;">중구행복돌봄센터</div>`,
        });
        infowindow.open(map, marker);
      } else {
        console.error("주소를 찾지 못했습니다.");
      }
      // 작업이 완료되면 로딩 상태를 false로 변경합니다.
      setIsLoading(false);
    });
  }, [address]);

  // 지도 컨테이너와 로딩 상태를 표시하는 UI를 렌더링합니다.
  return (
    <div>
      <div ref={container} style={{ width: "100%", height: "350px" }}></div>
      {isLoading && <p>지도를 로드 중입니다...</p>}
    </div>
  );
};

export default Map;
