// RestApi 불러오는 영역
const BASE_URL = "https://api.중구행복돌봄.kr/";

export async function getNotices({
  order = "createdAt",
  offset = 0,
  limit = 6,
}) {
  const str = "home/api/notice/";

  const response = await fetch(`${BASE_URL}${str}`);
  // if (!response.ok) {
  //   throw new Error("데이터를 불러오는데 실패했습니다");
  // }
  const body = await response.json();
  return body;
}

export async function getDatas({ order = "createdAt", offset = 0, limit = 6 }) {
  const str = "home/api/archive/";
  const response = await fetch(`${BASE_URL}${str}`);
  // if (!response.ok) {
  //   throw new Error("데이터를 불러오는데 실패했습니다");
  // }
  const body = await response.json();
  return body;
}

export async function getPhotos({
  order = "createdAt",
  offset = 0,
  limit = 6,
}) {
  const str = "home/api/poto_gallery/";
  const response = await fetch(`${BASE_URL}${str}`);
  // if (!response.ok) {
  //   throw new Error("데이터를 불러오는데 실패했습니다");
  // }
  const body = await response.json();
  return body;
}

//조직안내 이미지 받아오기
export async function getOrganization() {
  //보낼 Api 주소
  const str = "home/api/orgnizational_guidance/";

  //fetch로 보냄
  //const response = await fetch(`${BASE_URL}${str}`);
  const response = await fetch(`${BASE_URL}${str}`);

  const body = await response.json();
  return body;
}

//오시는길 Data 받아오기
export async function getDirections() {
  const str = "home/api/directions_get/";
  const response = await fetch(`${BASE_URL}${str}`);
  const data = await response.json();
  const { address, phoneNumber, subway, bus } = data[0];

  // 지하철과 버스 정보를 & 기준으로 분할하여 배열로 저장
  const subwayArr = subway.split("&");
  const busArr = bus.split("&");

  // 분할된 정보를 객체로 반환
  return {
    address,
    phoneNumber,
    subway: subwayArr,
    bus: busArr,
  };
}

export async function update(item, no) {
  const str = "home/api/poto_gallery/";

  const datas = JSON.parse(JSON.stringify(item));
  const hit = datas.hit + 1;
  datas.hit = hit;

  delete datas["files"];
  delete datas["thumb_files"];
  // console.log(`${BASE_URL}${str}${no}`, {
  //   method: "PUT",
  //   headers: {
  //     Accept: "application/json, text/plain",
  //     "Content-Type": "application/json;charset=UTF-8",
  //   },
  //   body: JSON.stringify(datas),
  // });
  const response = await fetch(`${BASE_URL}${str}${no}`, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify(datas),
  });
  // if (!response.ok) {
  //   throw new Error("데이터를 수정하는데 실패했습니다");
  // }
  const body = await response.json();
  return body;
}
