import styles from "./CareMPage.module.css";
import SubTitle from "../components/SubTitle";
// import CareList from "../components/CareList";
import { NavLink, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getList2 } from "../../api/";
import img1 from "../assets/care1.png";
import img2 from "../assets/care2.png";
import img3 from "../assets/care3.png";
import img4 from "../assets/care4.png";
import CareList from "../components/CareList";
import styled from "styled-components";
import main_img from "../assets/Main.png";

function CareMPage() {
  const name = "돌봄서비스";
  const value =
    "훈련된 전문 돌봄종사자들이 최적화된 서비스로 여러분을 찾아갑니다.";

  const [submenu, setSubMenu] = useState("장애인활동지원서비스");
  const no = useParams().no;

  const List = getList2(no);

  const style1 = {
    background: `url(${img1})  no-repeat`,
    backgroundSize: "100% 100%",
    width: "20%",
    marginLeft: "5%",
  };

  const style2 = {
    background: `url(${img2}) no-repeat`,
    backgroundSize: "100% 90%",
    width: "30%",
  };

  const style3 = {
    background: `url(${img3})  no-repeat`,
    backgroundSize: "100% 90%",
    width: "30%",
  };

  const style4 = {
    background: `url(${img4}) no-repeat`,
    backgroundSize: "100% 100%",
    width: "20%",
  };

  useEffect(() => {
    if (no === "1") {
      setSubMenu("장애인활동지원\n서비스");
    } else if (no === "2") {
      setSubMenu("노인장기요양\n서비스");
    } else if (no === "3") {
      setSubMenu("가사간병방문\n지원서비스");
    } else if (no === "4") {
      setSubMenu("돌봄SOS\n서비스");
    } else if (no === "5") {
      setSubMenu("서비스\n상담문의");
    }
  }, [no]);

  const activeStyle = {
    color: "#E5857C",
  };
  const activeStyle2 = {
    backgroundColor: "#FFF",
  };
  const style = {};
  if (no === "1") {
    style["height"] = "1100px";
  } else if (no === "2") {
    style["height"] = "1300px";
  } else if (no === "3") {
    style["height"] = "1100px";
  } else if (no === "4") {
    style["height"] = "1000px";
  } else if (no === "5") {
    style["height"] = "700px";
  }

  const StyledDiv = styled.div`
    width: 100%;
    float: left;
    margin-bottom: 50px;
    height: ${style["height"]};

    @media (max-width: 320px) {
      background : ${(back) => {
        if (no === "5") {
          return `url(${main_img}) 80% 25% no-repeat;`;
        } else return "none;";
      }}
    
      background-size : ${(backs) => {
        if (no === "5") return "40% 15%;";
        else return "none;";
      }}
      
      height: ${(height) => {
        if (no === "1") {
          return "1000px;";
        } else if (no === "2") {
          return "1200px;";
        } else if (no === "3") {
          return "1000px;";
        } else if (no === "4") {
          return "940px;";
        } else if (no === "5") {
          return "600px;";
        }
      }};
    }

  
    @media (min-width: 320px) {
      background : ${(back) => {
        if (no === "5") {
          return `url(${main_img}) 80% 22% no-repeat;`;
        } else return "none;";
      }}
    
      background-size : ${(backs) => {
        if (no === "5") return "40% 15%;";
        else return "none;";
      }}
      
      height: ${(height) => {
        if (no === "1") {
          return "950px;";
        } else if (no === "2") {
          return "1200px;";
        } else if (no === "3") {
          return "1000px;";
        } else if (no === "4") {
          return "940px;";
        } else if (no === "5") {
          return "700px;";
        }
      }};
    }
    @media (min-width: 375px) {
      background : ${(back) => {
        if (no === "5") {
          return `url(${main_img}) 80% 22% no-repeat;`;
        } else return "none;";
      }}
    
      background-size : ${(backs) => {
        if (no === "5") return "40% 15%;";
        else return "none;";
      }}
      

      height: ${(height) => {
        if (no === "1") {
          return "1100px;";
        } else if (no === "2") {
          return "1300px;";
        } else if (no === "3") {
          return "1100px;";
        } else if (no === "4") {
          return "1020px;";
        } else if (no === "5") {
          return "700px;";
        }
      }};
    }

    @media (min-width: 425px) {
    }

    @media (min-width: 768px) {
      height: ${(height) => {
        if (no === "1") {
          return "1400px;";
        } else if (no === "2") {
          return "1800px;";
        } else if (no === "3") {
          return "1400px;";
        } else if (no === "4") {
          return "1300px;";
        } else if (no === "5") {
          return "1200px;";
        }
      }};

      background : ${(back) => {
        if (no === "5") {
          return `url(${main_img}) 70% 15% no-repeat;`;
        } else return "none;";
      }}
    
      background-size : ${(backs) => {
        if (no === "5") return "40% 13%;";
        else return "none;";
      }}

    }
  `;

  return (
    <>
      <StyledDiv className={styles.Care}>
        <SubTitle name={name} value={value} />
        <ol className={styles.depth2} id="0">
          <li style={no === "1" ? activeStyle2 : undefined}>
            <NavLink
              to="/care/1"
              id={styles.menu}
              style={({ isActive }) => (no === "1" ? activeStyle : undefined)}
            >
              장애인활동지원
            </NavLink>
          </li>
          <li style={no === "2" ? activeStyle2 : undefined}>
            <NavLink
              to="/care/2"
              id={styles.menu}
              style={({ isActive }) => (no === "2" ? activeStyle : undefined)}
            >
              노인장기요양
            </NavLink>
          </li>
          <li style={no === "3" ? activeStyle2 : undefined}>
            <NavLink
              to="/care/3"
              id={styles.menu}
              style={({ isActive }) => (no === "3" ? activeStyle : undefined)}
            >
              가사간병방문지원
            </NavLink>
          </li>
          <li style={no === "4" ? activeStyle2 : undefined}>
            <NavLink
              to="/care/4"
              id={styles.menu}
              style={({ isActive }) => (no === "4" ? activeStyle : undefined)}
            >
              돌봄SOS
            </NavLink>
          </li>
        </ol>
        <h2 className={styles.subtitle}>{submenu}</h2>

        {no === "1" && <div className={styles.image} style={style1}></div>}
        {no === "2" && <div className={styles.image} style={style2}></div>}
        {no === "3" && <div className={styles.image} style={style3}></div>}
        {no === "4" && <div className={styles.image} style={style4}></div>}
        <CareList List={List} no={no} />
        {no === "5" && (
          <div className={styles.service}>
            <p>전화주시면 친절히 성심성의껏 답변해 드립니다.</p>

            <p>02-3789-2229</p>

            <p>팩스 : 02-2234-2229 | 이메일 : happycare0302@naver.com</p>
          </div>
        )}
      </StyledDiv>
    </>
  );
}

export default CareMPage;
