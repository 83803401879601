import styles from "./CareList.module.css";
import img1 from "../assets/care1.png";
import img2 from "../assets/care2.png";
import img3 from "../assets/care3.png";
import img4 from "../assets/care4.png";

import { useEffect, useState } from "react";

function CareList({ List, no }) {
  // const [style, setStyle] = useState({
  //   background: `url(${img1}) right top no-repeat`,
  //   backgroundSize: "100px",
  // });

  const style1 = {
    background: `url(${img1}) 95% top no-repeat`,
    backgroundSize: "15% 75%",
  };

  const style2 = {
    background: `url(${img2}) 92% top no-repeat`,
    backgroundSize: "25% 80%",
  };

  const style3 = {
    background: `url(${img3})95% top no-repeat`,
    backgroundSize: "15% 75%",
  };

  const style4 = {
    background: `url(${img4}) 92% top no-repeat`,
    backgroundSize: "20% 75%",
  };

  return (
    <>
      {no === "1" && <div className={styles.temp} style={style1}></div>}

      {no === "2" && <div className={styles.temp} style={style2}></div>}
      {no === "3" && <div className={styles.temp} style={style3}></div>}
      {no === "4" && <div className={styles.temp} style={style4}></div>}
      <div className={styles.CareList}>
        {no !== "5" && (
          <>
            <h1 className={styles.name}>{List["0"]["name"]}</h1>
            <div className={styles.list1}>
              <div className={styles.list2}>
                <p>{List["0"]["value"]} </p>
              </div>
            </div>
            <h1 className={styles.name}>{List["1"]["name"]}</h1>
            {no !== "3" && (
              <div className={styles.list3}>
                <p>
                  <span>・</span>
                  {List["1"]["value"]}
                </p>
              </div>
            )}
            {no === "3" && (
              <div className={styles.list3}>
                <p>
                  <span>・</span>
                  {List["1"]["value"][0]}
                </p>
                <p>
                  <span>・</span>
                  {List["1"]["value"][1]}
                </p>
              </div>
            )}

            <h1 className={styles.name}>{List["2"]["name"]}</h1>
            {no !== "4" && (
              <div className={styles.list3}>
                <p>
                  <span>・</span>
                  {List["2"]["value"]}
                </p>
              </div>
            )}

            <h1 className={styles.name}>{List["3"]["name"]}</h1>
            <div className={styles.list3}>
              <p>
                <span>・</span>
                {List["3"]["value"][0]}
              </p>
              <p>
                <span>・</span>
                {List["3"]["value"][1]}
              </p>
              <p>
                <span>・</span>
                {List["3"]["value"][2]}
              </p>
              <p>
                <span>・</span>
                {List["3"]["value"][3]}
              </p>
              {no === "4" && (
                <>
                  <p>
                    <span>・</span>
                    {List["3"]["value"][4]}
                  </p>
                  <p>
                    <span>・</span>
                    {List["3"]["value"][5]}
                  </p>
                  <p>
                    <span>・</span>
                    {List["3"]["value"][6]}
                  </p>
                  <p>
                    <span>・</span>
                    {List["3"]["value"][7]}
                  </p>
                </>
              )}
            </div>

            <h1 className={styles.name}>{List["4"]["name"]}</h1>
            {no !== "4" && (
              <div className={styles.list3}>
                {no !== "2" && (
                  <p>
                    <span>・</span>
                    {List["4"]["value"]}
                  </p>
                )}
                {no === "2" && (
                  <div>
                    <div className={styles.list4}></div>
                    <p> {List["4"]["value"]}</p>
                  </div>
                )}
              </div>
            )}

            <h1 className={styles.name}>{List["5"]["name"]}</h1>
            <div className={styles.list3}>
              <p>
                <span>・</span>
                {List["5"]["value"]}
              </p>
            </div>
          </>
        )}

        {no === "5" && (
          <div className={styles.tel}>
            <p className={styles.ph_txt1}>친절히 섬심성의껏 상담해 드립니다.</p>
            <p className={styles.ph_txt2}>02-3789-2229</p>
          </div>
        )}
      </div>
    </>
  );
}

export default CareList;
