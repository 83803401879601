import styles from "./CarePage.module.css";
import SubTitle from "../components/SubTitle";
import CareList from "../components/CareList";
import { NavLink, useParams } from "react-router-dom";
import Location from "../components/Location";
import SubTel from "../components/SubTel";
import { useState, useEffect } from "react";
import { getList } from "../api/";

function CarePage() {
  const name = "돌봄서비스";
  const value =
    "훈련된 전문 돌봄종사자들이 최적화된 서비스로 여러분을 찾아갑니다.";
  const menu = "돌봄서비스";

  const [submenu, setSubMenu] = useState("장애인활동지원서비스");
  const no = useParams().no;

  const List = getList(no);

  const linked = "/care/1";

  const style = {};
  if (no === "1") {
    style["height"] = "1200px";
  } else if (no === "2") {
    style["height"] = "1200px";
  } else if (no === "3") {
    style["height"] = "1200px";
  } else if (no === "4") {
    style["height"] = "1200px";
  } else if (no === "5") {
    style["height"] = "700px";
  }

  useEffect(() => {
    if (no === "1") {
      setSubMenu("장애인활동지원서비스");
    } else if (no === "2") {
      setSubMenu("노인장기요양서비스");
    } else if (no === "3") {
      setSubMenu("가사간병방문지원서비스");
    } else if (no === "4") {
      setSubMenu("돌봄SOS서비스");
    } else if (no === "5") {
      setSubMenu("서비스 상담 문의");
    }
  }, [no]);

  const activeStyle = {
    color: "#E5857C",
    backgroundColor: "#FBF1F0",
  };

  return (
    <div className={styles.Care}>
      <div className={styles.left}>
        <SubTitle name={name} value={value} />

        <ol className={styles.depth2} id="0">
          <li>
            <NavLink
              to="/care/1"
              id={styles.menu}
              style={({ isActive }) => (no === "1" ? activeStyle : undefined)}
            >
              장애인활동지원서비스
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/care/2"
              id={styles.menu}
              style={({ isActive }) => (no === "2" ? activeStyle : undefined)}
            >
              노인장기요양서비스
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/care/3"
              id={styles.menu}
              style={({ isActive }) => (no === "3" ? activeStyle : undefined)}
            >
              가사간병방문지원서비스
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/care/4"
              id={styles.menu}
              style={({ isActive }) => (no === "4" ? activeStyle : undefined)}
            >
              돌봄SOS서비스
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/care/5"
              id={styles.menu}
              style={({ isActive }) => (no === "5" ? activeStyle : undefined)}
            >
              서비스 상담 문의
            </NavLink>
          </li>
        </ol>

        <SubTel height="3" />
      </div>

      <div id={styles.right} style={style}>
        <Location menu={menu} submenu={submenu} link={linked} />
        <h2>{submenu}</h2>
        <div className={styles.Container}>
          <CareList List={List} no={no} />
        </div>
      </div>
    </div>
  );
}

export default CarePage;
