import { Link, useLocation } from "react-router-dom";
import styles from "./Navm.module.css";
import { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import btnImg from "../assets/btn.png";

function Navm() {
  const width = 280;
  const [isOpen, setOpen] = useState(false);

  const locaion = useLocation();

  const el = useRef();

  // button 클릭 시 토글
  const toggleMenu = () => {
    if (isOpen === false) {
      setOpen((prevState) => {
        return true;
      });
    } else {
      setOpen((prevState) => {
        return false;
      });
    }
  };

  useEffect(() => {
    setOpen(false);
  }, [locaion]);

  const handleClose = (event) => {
    // console.log(isOpen);
    if (isOpen && !el.current.contains(event.target)) {
      if (event.target.id !== "toggle") toggleMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, [isOpen]);

  return (
    <>
      <div className={styles.Navm}>
        <h1 className={styles.top}>
          <Link
            to="/"
            title="홈 바로가기"
            style={{
              width: "52%",
              height: "55px",
              display: "block",
              marginLeft: "25%",
              float: "left",
            }}
          >
            <span className={styles.hidden}>중구행복돌봄센터</span>
          </Link>
        </h1>
        <button className={styles.Nav} onClick={toggleMenu}>
          <img id="toggle" src={btnImg} alt="메뉴" />
        </button>
      </div>
      {isOpen && (
        <div className={styles.sidebar} ref={el}>
          <Sidebar />
        </div>
      )}
    </>
  );
}

export default Navm;
