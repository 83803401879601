import styles from "./ImageItemList.module.css";
import { useState, useEffect } from "react";
import btnimg from "../assets/btn.png";
import PageNav from "./PageNav";
import { Link } from "react-router-dom";

function formatDate(value) {
  const date = new Date(value);
  return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`;
}

function ItemListItem({ item, order }) {
  //   console.log(order + item.id);

  const style = {
    background: `#ffffff url('${item.thumb_files}')center center no-repeat`,
    backgroundSize: "cover",
  };
  // console.log(item.hit);

  return (
    <div className={styles.ItemListItem}>
      <Link to={order + item.id}>
        <div>
          {!item.thumb_files && <div className={styles.image}></div>}
          {item.thumb_files && (
            <div className={styles.image2} style={style}>
              {/* <img className={styles.pre} src={item.files} alt={item.title} /> */}
            </div>
          )}

          <p>{item.title} </p>
          <p>등록일 {formatDate(item.createdAt)}</p>
          <p>조회수 {item.hit}</p>
        </div>
      </Link>
    </div>
  );
}

function ImageItemList({ items, order }) {
  let length = items.length;
  const offset = 9;
  let count = 0;
  Object.keys(items).map((key) => {
    if (items[key].isPublic === true) {
      count = count + 1;
    }
    return undefined;
  });
  //   console.log(count);
  const [current_page, set_Current_Page] = useState(1);
  const [page, setPage] = useState(
    Math.ceil((items.length - offset) / (offset - count)) + 1
  );
  //   console.log(items);
  let cnt = 0;

  const [selected, setSelected] = useState(1);
  const [searchItem, setSearchItem] = useState(items);
  const [isSearch, setIsSearch] = useState(false);

  const findItem = (search, selected, items) => {
    console.log(`검색단어 : ${search}`);
    console.log(`select : ${selected}`);
    console.log(items);

    const item = [];
    Object.keys(items).map((key) => {
      if (items[key].isPublic === true) {
        item.push(items[key]);
      }
      return undefined;
    });

    if (selected === 1) {
      Object.keys(items).map((key) => {
        if (
          items[key].title.includes(search) ||
          items[key].summary.includes(search)
        ) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
        return undefined;
      });
    } else if (selected === 2) {
      Object.keys(items).map((key) => {
        if (items[key].title.includes(search)) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
        return undefined;
      });
    } else if (selected === 3) {
      Object.keys(items).map((key) => {
        if (items[key].summary.includes(search)) {
          if (items[key].isPublic === false) item.push(items[key]);
        }

        return undefined;
      });
    }

    return item;
  };

  const handleChange = (num) => {
    set_Current_Page(Number(num));
  };

  const handleOnClick = () => {
    const text = document.getElementById("search");
    let item;
    setIsSearch(true);

    if (text.value === "") setIsSearch(false);
    item = findItem(text.value, selected, items);
    if (item) {
      setPage(Math.ceil((item.length - offset) / (offset - count)) + 1);

      length = item.length;
      setSearchItem(item);
    }
  };

  const handleSelected = (e) => {
    const value = e.target.value;

    if (value === "전체") setSelected(1);
    else if (value === "제목") setSelected(2);
    else if (value === "내용") setSelected(3);
  };

  const handleOnkeyPress = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      handleOnClick();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefult();
  };

  useEffect(() => {
    setPage(
      (prevItem) => Math.ceil((items.length - offset) / (offset - count)) + 1
    );
  }, [items, count]);

  // console.log(items);
  return (
    <>
      <div className={styles.search}>
        <form onSubmit={handleSubmit}>
          <select onChange={handleSelected}>
            <option>전체</option>
            <option>제목</option>
            <option>내용</option>
          </select>
          <input type="text" id="search" onKeyDown={handleOnkeyPress}></input>
          <input type="text" className={styles.hidden} />
          <button type="button">
            <img src={btnimg} alt="button" onClick={handleOnClick}></img>
          </button>
        </form>
      </div>

      {!isSearch && (
        <>
          <h3 className={styles.state}>
            전체 게시물 {length}개, 현재 {current_page}페이지 / 전체 {page}
            페이지
          </h3>

          <ul className={styles.table}>
            {Object.keys(items).map((key, index) => {
              if (items[key].isPublic === true) {
                cnt = cnt + 1;
                return (
                  <li key={items[key].id}>
                    <ItemListItem item={items[key]} order={order} />
                  </li>
                );
              }

              if (index - cnt < offset - cnt) {
                if (current_page === 1) {
                  return (
                    <li key={items[key].id}>
                      <ItemListItem item={items[key]} order={order} />
                    </li>
                  );
                }
              } else if (
                index - cnt >= (offset - cnt) * (current_page - 1) &&
                index - cnt < (offset - cnt) * current_page
              ) {
                if (current_page !== 1) {
                  return (
                    <li key={items[key].id}>
                      <ItemListItem item={items[key]} order={order} />
                    </li>
                  );
                }
              } else return undefined;

              return undefined;
            })}
          </ul>
          <PageNav cnt={page} page={current_page} change={handleChange} />
        </>
      )}
      {isSearch && (
        <>
          <h3 className={styles.state}>
            전체 게시물 {length}개, 현재 {current_page}페이지 / 전체 {page}
            페이지
          </h3>

          <ul className={styles.table}>
            <ul className={styles.colum}>
              <li>번호</li>
              <li>제목</li>
              <li>작성자</li>
              <li>등록일</li>
            </ul>
            {Object.keys(searchItem).map((key, index) => {
              if (searchItem[key].isPublic === true) {
                cnt = cnt + 1;
                return (
                  <li key={searchItem[key].id}>
                    <ItemListItem item={searchItem[key]} order={order} />
                  </li>
                );
              }

              if (index - cnt < offset - cnt) {
                if (current_page === 1) {
                  return (
                    <li key={searchItem[key].id}>
                      <ItemListItem item={searchItem[key]} order={order} />
                    </li>
                  );
                }
              } else if (
                index - cnt >= (offset - cnt) * (current_page - 1) &&
                index - cnt < (offset - cnt) * current_page
              ) {
                if (current_page !== 1) {
                  return (
                    <li key={searchItem[key].id}>
                      <ItemListItem item={searchItem[key]} order={order} />
                    </li>
                  );
                }
              } else return undefined;

              return undefined;
            })}
          </ul>
          <PageNav cnt={page} page={current_page} change={handleChange} />
        </>
      )}
    </>
  );
}

export default ImageItemList;
