import styles from "./SubTel.module.css";

function SubTel({ height }) {
  const style = {};

  if (height === "1") {
    style["top"] = "200px";
  } else if (height === "2") {
    style["top"] = "200px";
  } else if (height === "3") {
    style["top"] = "400px";
  } else if (height === "4") {
    style["top"] = "350px";
  }
  return (
    <div id={styles.SubTel} style={style}>
      <h3 className={styles.ph_title}>서비스문의</h3>
      <div className={styles.left_ph}>
        <p className={styles.ph_txt1}>친절히 섬심성의껏 상담해 드립니다.</p>
        <p className={styles.ph_txt2}>02-3789-2229</p>
        <p className={styles.ph_txt3}>팩스 : 02-2234-2229</p>
        <p className={styles.ph_txt4}>이메일 : happycare0302@naver.com</p>
      </div>
    </div>
  );
}

export default SubTel;
