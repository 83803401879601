import { Link } from "react-router-dom";
import styles from "./HomePage.module.css";
import classNames from "classnames";
import moreImg from "../assets/more.png";
import { useState } from "react";
import Preview from "../components/Preview";
import { getDatas, getNotices, getOrganiztion, getPhotos } from "../Api";

function HomePage() {
  const [isOne, setIsOne] = useState(true);

  const handleBoard = (chose) => {
    if (chose === 1) {
      setIsOne(false);
    } else {
      setIsOne(true);
    }
  };

  return (
    <>
      <div id={styles.m_key_box}>
        <div className={styles.m_key}>
          <p className={styles.m_key_txt}>
            <span className={styles.hidden}>
              도움의 손길이 필요할 때, 중구행복돌봄센터가 함께하겠습니다.
            </span>
          </p>
        </div>
      </div>
      <div id={styles.m_container}>
        <div id={styles.m_contents2_box}>
          <div id={styles.ctn2_quick}>
            <h3 className={styles.ctn_title}>서비스안내</h3>
            <p className={styles.ctn2_txt}>
              중구행복돌봄센터 서비스안내입니다.
            </p>
            <ul className={styles.care_list}>
              <li className={styles.sv1}>
                <Link to="/care/1"></Link>
              </li>
              <li className={styles.sv2}>
                <Link to="/care/2"></Link>
              </li>
              <li className={styles.sv3}>
                <Link to="/care/3"></Link>
              </li>
              <li className={styles.sv4}>
                <Link to="/care/4"></Link>
              </li>
            </ul>
          </div>

          <div id={styles.ctn2_tel}>
            <h3 className={styles.ctn_title2}>서비스문의</h3>
            <p className={styles.ctn3_txt}>
              전화주시면 친절히 섬심 성의껏 답변해 드립니다.
            </p>
            <p className={styles.ctn3_tel1}>&nbsp;02-3789-2229</p>
            <dl className={styles.ctn3_tel2}>
              <dt>팩스 : </dt>
              <dd>02-2234-2229</dd>
              <dt>이메일 : </dt>
              <dd>happycare0302@naver.com</dd>
            </dl>
          </div>
        </div>
        <div id={styles.m_contents3_box}>
          <div id={styles.Tabbox2}>
            <h3 className={styles.hidden}>공지사항</h3>
            <div
              className={classNames(
                styles.tab_list,
                isOne ? styles.m1 : styles.m2
              )}
            >
              <ul>
                <li className={styles.m1}>
                  <Link
                    to="#"
                    onClick={() => {
                      handleBoard(0);
                    }}
                  >
                    <span>공지사항</span>
                  </Link>
                  <Preview
                    address="/notice"
                    getdata={getNotices}
                    isPhoto={false}
                  />
                </li>

                <li className={styles.m2}>
                  <Link
                    to="#"
                    onClick={() => {
                      handleBoard(1);
                    }}
                  >
                    <span>일반자료실</span>
                  </Link>

                  <Preview address="/data" getdata={getDatas} isPhoto={false} />
                </li>
              </ul>
            </div>
          </div>
          <div id={styles.ctn3_photo}>
            <h3 className={styles.ctn_title4}>포토갤러리</h3>
            <Preview address="/photo" getdata={getPhotos} isPhoto={true} />
            <Link to="/photo" className={styles.more}>
              <img src={moreImg} width="30px" alt="포토갤러리 더보기" />
            </Link>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default HomePage;
