import styles from "./Preview.module.css";
import { Link } from "react-router-dom";
import { getNotices } from "../Api";
import { useState, useEffect, useCallback } from "react";
import useAsync from "../hooks/useAsync";
import moreImg from "../assets/more.png";
import { Sort } from "../utils/Sort";

const LIMIT = 6;

function formatDate(value) {
  const date = new Date(value);
  return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`;
}

function ItemListItem({ item, order }) {
  //   console.log(order + item.id);

  return (
    <>
      <Link className={styles.subtitle} to={order + "/" + item.id}>
        {item.title}
      </Link>
      <span>
        <p>{formatDate(item.createdAt)}</p>
      </span>
    </>
  );
}

function ImageListItem({ item, order }) {
  const style = {
    background: `#f4f4f4 url('${item.thumb_files}')center center no-repeat`,
    backgroundSize: "cover",
  };

  return (
    <>
      <Link to={order + "/" + item.id}>
        {!item.thumb_files && <div className={styles.photo}></div>}
        {item.thumb_files && <div className={styles.photo} style={style}></div>}
      </Link>

      <Link className={styles.p_title} to={order + "/" + item.id}>
        {item.title}
      </Link>
    </>
  );
}

function Preview({ address, getdata, isPhoto }) {
  const [order, setOrder] = useState("createdAt");
  const [isLoading, loadingError, getNoticessAsync] = useAsync(getdata);
  const [items, setItems] = useState([]);
  const sortedItems = Sort(items.sort((a, b) => b["id"] - a["id"]));

  const handleLoad = useCallback(
    async (options) => {
      const result = await getNoticessAsync(options);
      //   console.log("확인");
      if (!result) return;

      const datas = result;
      //   console.log(datas);
      if (options.offset === 0) {
        setItems(datas);
      } else {
        setItems((prevItems) => [...prevItems, ...datas]);
      }
      //   setHasNext(paging.hasNext);
    },
    [getNoticessAsync]
  );

  useEffect(() => {
    handleLoad({ order, offset: 0, limit: LIMIT });
  }, []);

  return (
    <ul className={styles.Preview}>
      {!isPhoto && (
        <>
          {Object.keys(sortedItems).map((key, index) => {
            if (index < 5) {
              return (
                <li key={sortedItems[key].id}>
                  <ItemListItem item={sortedItems[key]} order={address} />
                </li>
              );
            }
          })}
          <li className={styles.more}>
            <Link to={address}>
              <img src={moreImg} width="30px" alt="더보기" />
            </Link>
          </li>
        </>
      )}

      {isPhoto && (
        <>
          {Object.keys(sortedItems).map((key, index) => {
            if (index < 2) {
              return (
                <li className={styles.preImage} key={sortedItems[key].id}>
                  <ImageListItem item={sortedItems[key]} order={address} />
                </li>
              );
            }
          })}
        </>
      )}
    </ul>
  );
}

export default Preview;
