import { Link } from "react-router-dom";
import styles from "./QuickM.module.css";
import bannerImg from "../../assets/service.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function QuickM() {
  const location = useLocation();
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    if (location.pathname === "/care/5") {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [location.pathname]);

  return (
    <div className={styles.quick}>
      {display && (
        <div className={styles.q_quick}>
          <div className={styles.q_q_menu}>
            <ul>
              <li>
                <Link to="/care/5">
                  <img src={bannerImg} alt="서비스 바로가기"></img>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuickM;
