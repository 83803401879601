import styles from "./IntroPage.module.css";
import SubTitle from "../components/SubTitle";
import Map from "../components/Map";
import { NavLink, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import History from "../components/History";
import styled from "styled-components";

function IntroPage() {
  const name = "센터소개";
  const value =
    "중구행복돌봄센터는 함께하는 가치로 사람중심,\n상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다.";

  const [submenu, setSubMenu] = useState("인사말");
  const no = useParams().no;

  const [toggle, setToggle] = useState(1);
  const activeStyle = {
    color: "#E5857C",
    fontSize: "14px",
  };
  const activeStyle2 = {
    backgroundColor: "#FFF",
  };

  const style = {};

  if (no === "1") {
    style["height"] = "900";
  } else if (no === "2") {
    style["height"] = "auto";
  } else if (no === "3") {
    style["height"] = "auto";
  } else if (no === "4") {
    style["height"] = "auto";
  }

  useEffect(() => {
    if (no === "1") {
      setSubMenu("인사말");
      setToggle(1);
    } else if (no === "2") {
      setSubMenu("연혁");
      setToggle(2);
    } else if (no === "3") {
      setSubMenu("조직안내");
      setToggle(3);
    } else if (no === "4") {
      setSubMenu("오시는길");
      setToggle(4);
    }
  }, [no]);

  const StyledDiv = styled.div`
    width: 100%;
    float: left;
    margin-bottom: 50px;
    height: ${style["height"]};

    @media (min-width: 320px) {
      height: ${(height) => {
        if (no === "1") {
          return "800px;";
        } else if (no === "2") {
          return "auto;";
        } else if (no === "3") {
          return "auto;";
        } else if (no === "4") {
          return "auto;";
        }
      }};
    }
    @media (min-width: 375px) {
      height: ${(height) => {
        if (no === "1") {
          return "910px;";
        } else if (no === "2") {
          return "auto;";
        } else if (no === "3") {
          return "auto;";
        } else if (no === "4") {
          return "auto;";
        }
      }};
    }

    @media (min-width: 425px) {
      height: ${(height) => {
        if (no === "1") {
          return "950px;";
        } else if (no === "2") {
          return "auto;";
        } else if (no === "3") {
          return "auto;";
        } else if (no === "4") {
          return "auto;";
        }
      }};
    }

    @media (min-width: 768px) {
      height: ${(height) => {
        if (no === "1") {
          return "1400px;";
        } else if (no === "2") {
          return "auto;";
        } else if (no === "3") {
          return "auto;";
        } else if (no === "4") {
          return "auto;";
        }
      }};
    }
  `;

  return (
    <StyledDiv className={styles.Intro}>
      <SubTitle name={name} value={value} />
      <ol className={styles.depth2} id="0">
        <li style={no === "1" ? activeStyle2 : undefined}>
          <NavLink
            to="/introduce/1"
            id={styles.menu}
            style={({ isActive }) => (no === "1" ? activeStyle : undefined)}
          >
            인사말
          </NavLink>
        </li>
        <li style={no === "2" ? activeStyle2 : undefined}>
          <NavLink
            to="/introduce/2"
            id={styles.menu}
            style={({ isActive }) => (no === "2" ? activeStyle : undefined)}
          >
            연혁
          </NavLink>
        </li>
        <li style={no === "3" ? activeStyle2 : undefined}>
          <NavLink
            to="/introduce/3"
            id={styles.menu}
            style={({ isActive }) => (no === "3" ? activeStyle : undefined)}
          >
            조직안내
          </NavLink>
        </li>
        <li style={no === "4" ? activeStyle2 : undefined}>
          <NavLink
            to="/introduce/4"
            id={styles.menu}
            style={({ isActive }) => (no === "4" ? activeStyle : undefined)}
          >
            오시는길
          </NavLink>
        </li>
      </ol>

      <h2 className={styles.subtitle}>{submenu}</h2>
      {toggle === 1 && (
        <div className={styles.one}>
          <div className={styles.two}>
            <h1>
              중구행복돌봄센터 홈페이지를 <br></br>방문해 주셔서 감사합니다!
            </h1>
            <p>중구행복돌봄센터는 서울중구지역자활센터에서</p>
            <p>2005년 가사간병사업을 시작으로 사회서비스사업을</p>
            <p>개시하여 노인돌봄종합서비스, 장애인활동지원,</p>
            <p>재가장기요양(방문요양) 등의 서비스를 제공해 왔습니다.</p>
            <p className={styles.p2}>운영법인 사회적기업 에스이임파워는</p>
            <p>사회적경제 (Social Economy)에 속한 모든 조직의</p>
            <p>역량강화 (Empower)에 기여하기 위한 사명을 갖고</p>
            <p>설립된 사회적협동조합입니다.</p>
            <p>‘사회적기업가 육성사업’, ‘디지털사회혁신’,</p>
            <p>‘사회주택’, ‘지역자활센터 운영’ 등의 다양한 사회혁신</p>
            <p>사업을 하고 있으며, 돌봄사회서비스를 통한 사회적</p>
            <p>책임을 강화하기 위하여 2022년 1월</p>
            <p>서울중구지역자활센터에서</p>
            <p>중구행복돌봄센터를 법인의 지점으로 분리하였습니다.</p>
          </div>
          <div className={styles.three}>
            <p>중구행복돌봄센터는 장애인활동지원사업,</p>
            <p>가사간병방문지원사업, 재가장기요양(방문요양),</p>
            <p>돌봄SOS 등 돌봄이 필요한 이웃을 지원하는</p>
            <p>돌봄사회서비스를 제공하고 있습니다.</p>
            <p>돌봄 노동자들과 돌봄의 손길이 필요한</p>
            <p>이웃들과 함께하며 삶의 질을 높이고,</p>
            <p>행복한 삶을 지키는 작은 희망이 되겠습니다. </p>
          </div>
        </div>
      )}
      {toggle === 2 && <History />}
      {toggle === 3 && <div className={styles.orga}></div>}
      {toggle === 4 && (
        <div>
          <Map />

          <ol className={styles.list}>
            <li className={styles.list2}>
              <h1>주소</h1>
              <ol>
                <li className={styles.addr}>
                  (우) 04577 서울시 중구 다산로 44길 11, 3층
                </li>
                <li className={styles.addr}>TEL : 02-3789-2229</li>
              </ol>
            </li>
            <li className={styles.list2}>
              <h1>대중교통</h1>
              <ol>
                <li className={styles.list3}>
                  <p>지하철</p>
                  <ol>
                    <li>- &nbsp; &nbsp;2호선 신당역 4번출구 200m</li>
                    <li>- &nbsp; &nbsp;6호선 신당역 6번출구 139m</li>
                  </ol>
                </li>
                <li className={styles.list3}>
                  <p>버스</p>
                  <ol>
                    <li>
                      - &nbsp; &nbsp;142번, 147번, 421번, 463번, 6211번, 2012번,
                      2014번, 2015번
                    </li>
                    <li>- &nbsp; &nbsp;신당역, 중앙시장앞 하차</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      )}
    </StyledDiv>
  );
}

export default IntroPage;
