import styles from "./PageNav.module.css";
import preimg from "../../assets/pre.png";
import nextimg from "../../assets/next.png";
import lastimg from "../../assets/last.png";

function PageNav({ cnt, page, change }) {
  const handleOnClick = (e) => {
    const value = e.target.id;

    if (value === "pre") {
      if (page - 1 !== 0) {
        change(page - 1);
      }
    } else if (value === "next") {
      if (page + 1 <= cnt) {
        change(page + 1);
      }
    } else if (value === "last") {
      change(cnt);
    } else {
      change(value);
    }
  };

  const makeButton = () => {
    var buttons = [];

    let active = false;
    for (var i = 0; i < cnt; i++) {
      if (page === i + 1) active = true;
      else active = false;
      buttons.push(
        <button
          key={i}
          id={i + 1}
          className={styles.page}
          onClick={handleOnClick}
          style={active ? { color: "#E5857C" } : undefined}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className={styles.PageNav}>
      <button className={styles.navi} onClick={handleOnClick}>
        <img src={preimg} alt="pre" id="pre"></img>
      </button>
      {makeButton()}
      <button className={styles.navi} id="next" onClick={handleOnClick}>
        <img src={nextimg} alt="next" id="next"></img>
      </button>

      <button className={styles.navi2} id="last" onClick={handleOnClick}>
        <img src={lastimg} alt="last" id="last"></img>
      </button>
    </div>
  );
}

export default PageNav;
