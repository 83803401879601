import styles from "./Footer.module.css";

function Footer({ address, phoneNumber }) {
  //console.log("Footer 주소 : ", address);
  //console.log("Footer 전화번호 : ", phoneNumber);
  // 우편번호를 찾기 위한 정규 표현식을 생성합니다.
  // (우) 뒤에 오는 공백을 포함한 다섯 자리 숫자를 찾습니다.
  const postalCodeRegex = /\(우\)\s\d{5}/;

  // 주소에서 우편번호를 찾아냅니다.
  const postalCode =
    address && address.match(postalCodeRegex)
      ? address.match(postalCodeRegex)[0]
      : "";

  // 주소에서 우편번호 부분을 제거합니다.
  const realAddress = address
    ? address.replace(postalCodeRegex, "").trim()
    : "";

  //console.log("우편번호 : ", postalCode); // (우) *****
  //console.log("주소 : ", realAddress); // 서울시 중구 다산로 44길 11, 4층

  return (
    <div id={styles.footer}>
      <div id={styles.footer_box}>
        <div id={styles.copy}>
          <div id={styles.copy_box}>
            <address>
              <p className={styles.f_addr}>
                <span className={styles.mg_right20}>{postalCode}</span>
                <span className={styles.mg_right20}>{realAddress}</span>
                <span className={styles.mg_right20}>대표 : 오병전 </span>
                <span className={styles.mg_right20}>
                  사업자등록번호 : 784-82-00462
                </span>
              </p>
              <p className={styles.f_tel}>
                <span className={styles.mg_right20}>
                  전화번호 : {phoneNumber}
                </span>
                <span className={styles.mg_right20}>팩스 : 02-2234-2229</span>
                <span className={styles.mg_right20}>
                  이메일 : happycare0302@naver.com
                </span>

                <span className={styles.mg_right20}>
                  Copyright@2004 중구행복돌봄센터 All right Reserved.
                </span>
              </p>
            </address>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
