import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./components/App";
import SiteMap from "./pages/SiteMap";
import CarePage from "./pages/CarePage";
import HomePage from "./pages/HomePage";
import IntroducePage from "./pages/IntroducePage";
import NoticePage from "./pages/NoticePage";
import NoticePages from "./pages/NoticePages";
import DataPage from "./pages/DataPage";
import DataPages from "./pages/DataPages";
import PhotoPage from "./pages/PhotoPage";
import PhotoPages from "./pages/PhotoPages";
import MainPage from "./App/pages/MainPage";
import { isMobile } from "react-device-detect";
import IntroPage from "./App/pages/IntroPage";
import CareMPage from "./App/pages/CareMPage";
import MPage from "./App/pages/MPage";
import MPages from "./App/pages/MPages";

import React, { useEffect, useState } from "react";

import { getNotices, getDatas, getPhotos } from "./Api";
import Site from "./App/pages/Site";
function Main() {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    setShowComponent(true);
  }, []);

  return (
    <BrowserRouter>
      <App>
        <Routes>
          {!isMobile && (
            <>
              {showComponent && (
                <>
                  <Route path="/" element={<HomePage />}></Route>
                  <Route path="/sitemap" element={<SiteMap />}></Route>
                  <Route
                    path="/introduce/:no"
                    element={<IntroducePage />}
                  ></Route>
                  <Route path="/care/:no" element={<CarePage />}></Route>
                  <Route path="/notice" element={<NoticePage />}></Route>
                  <Route path="/notice/:no" element={<NoticePages />}></Route>
                  <Route path="/data" element={<DataPage />}></Route>
                  <Route path="/data/:no" element={<DataPages />}></Route>
                  <Route path="/photo" element={<PhotoPage />}></Route>
                  <Route path="/photo/:no" element={<PhotoPages />}></Route>
                </>
              )}
            </>
          )}
          {isMobile && (
            <>
              {showComponent && (
                <>
                  <Route path="/" element={<MainPage />}></Route>
                  <Route path="/sitemap" element={<Site />}></Route>
                  <Route path="/introduce/:no" element={<IntroPage />}></Route>
                  <Route path="/care/:no" element={<CareMPage />}></Route>
                  <Route
                    path="/notice"
                    element={
                      <MPage
                        page={"/notice/"}
                        getData={getNotices}
                        name={"공지사항"}
                        value={
                          "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다."
                        }
                      />
                    }
                  ></Route>
                  <Route
                    path="/notice/:no"
                    element={
                      <MPages
                        page={"/notice/"}
                        getData={getNotices}
                        name={"공지사항"}
                        value={
                          "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다."
                        }
                      />
                    }
                  ></Route>

                  <Route
                    path="/data"
                    element={
                      <MPage
                        page={"/data/"}
                        getData={getDatas}
                        name={"일반자료실"}
                        value={
                          "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다."
                        }
                      />
                    }
                  ></Route>
                  <Route
                    path="/data/:no"
                    element={
                      <MPages
                        page={"/data/"}
                        getData={getDatas}
                        name={"일반자료실"}
                        value={
                          "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다."
                        }
                      />
                    }
                  ></Route>

                  <Route
                    path="/photo"
                    element={
                      <MPage
                        page={"/photo/"}
                        getData={getPhotos}
                        name={"포토갤러리"}
                        value={
                          "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다."
                        }
                      />
                    }
                  ></Route>
                  <Route
                    path="/photo/:no"
                    element={
                      <MPages
                        page={"/photo/"}
                        getData={getPhotos}
                        name={"포토갤러리"}
                        value={
                          "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다."
                        }
                      />
                    }
                  ></Route>
                </>
              )}
            </>
          )}
        </Routes>
      </App>
    </BrowserRouter>
  );
}

export default Main;
