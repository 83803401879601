import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import styles from "./App.module.css";
import Footer from "./Footer";
import Quick from "./Quick";
import { BrowserView, MobileView } from "react-device-detect";

import Navm from "../App/components/Navm";
import Footerm from "../App/components/Footerm";
import QuickM from "../App/components/QuickM";
import { getDirections } from "../Api"; // getDirections를 import합니다.

function App({ children }) {
  const [address, setAddress] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const directions = await getDirections();
      setAddress(directions.address);
      setPhoneNumber(directions.phoneNumber);
    };

    fetchData();
  }, []);

  return (
    <>
      <BrowserView>
        <Nav className={styles.nav} />
        <div className={styles.body}>{children}</div>
        <Footer
          className={styles.Footer}
          address={address}
          phoneNumber={phoneNumber}
        />
        <Quick className={styles.Quick} />
      </BrowserView>

      <MobileView>
        <Navm side={false} />
        <div className={styles.body}>{children}</div>
        <Footerm address={address} phoneNumber={phoneNumber} />
        <QuickM />
      </MobileView>
    </>
  );
}

export default App;
