import styles from "./History.module.css";

function History() {
  return (
    <div className={styles.History}>
      <div className={styles.table}>
        <h3 className={styles.year}>2022</h3>
        <ol>
          <li>
            <h4>1월 12일</h4>
            <p>SE임파워사회적협동조합 법인지점 중구행복돌봄센터 사업자등록</p>
            <span>(소재지 : 서울시 중구 다산로 44길 11, 3층)</span>
          </li>
        </ol>
      </div>
      <div className={styles.table}>
        <h3 className={styles.year}>2021</h3>
        <ol>
          <li>
            <h4>12월</h4>
            <p>돌봄SOS 우수기관 평가 선정</p>
          </li>
        </ol>
      </div>

      <div className={styles.table}>
        <h3 className={styles.year}>2020</h3>
        <ol>
          <li>
            <h4>8월</h4>
            <p>돌봄SOS 사업개시</p>
          </li>
          <li>
            <h4>3월</h4>
            <p>SE임파워사회적협동조합으로 법인변경</p>
          </li>
        </ol>
      </div>

      <div className={styles.table}>
        <h3 className={styles.year}>2013</h3>
        <ol>
          <li>
            <h4>6월</h4>
            <p>재가장기요양기관 우수기관 선정</p>
          </li>
        </ol>
      </div>

      <div className={styles.table}>
        <h3 className={styles.year}>2009</h3>
        <ol>
          <li>
            <h4>3월</h4>
            <p>장애인활동보조지원 사업 지정</p>
          </li>
        </ol>
      </div>

      <div className={styles.table}>
        <h3 className={styles.year}>2008</h3>
        <ol>
          <li>
            <h4>9월</h4>
            <p>재가장기요양기관(방문요양) 설치 신고</p>
          </li>
        </ol>
      </div>

      <div className={styles.table}>
        <h3 className={styles.year}>2007</h3>
        <ol>
          <li>
            <h4>5월</h4>
            <p>노인돌보미 바우처사업 지정</p>
          </li>
        </ol>
      </div>

      <div className={styles.table}>
        <h3 className={styles.year}>2005</h3>
        <ol>
          <li>
            <h4>1월</h4>
            <p>복권기금 가사간병사업 개시</p>
          </li>
        </ol>
      </div>

      <div className={styles.table}>
        <h3 className={styles.year}>2004</h3>
        <ol>
          <li>
            <h4>4월</h4>
            <p>중구자활후견기관 보건복지부 지정 </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default History;
