import List from "./mock.json";

export function getList(id) {
  if (!id) return List["List1"];

  if (Number(id) === 1) return List["List1"];
  else if (Number(id) === 2) return List["List2"];
  else if (Number(id) === 3) return List["List3"];
  else if (Number(id) === 4) return List["List4"];
  else return List["List1"];
}

export function getList2(id) {
  if (!id) return List["List5"];

  if (Number(id) === 1) return List["List5"];
  else if (Number(id) === 2) return List["List6"];
  else if (Number(id) === 3) return List["List7"];
  else if (Number(id) === 4) return List["List8"];
  else return List["List5"];
}
