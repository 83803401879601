import styled from "styled-components";
import backImg from "../../assets/title_bg.png";

function SubTitle({ name, value }) {
  const StyledDiv = styled.div`
    position: relative;
    width: 100%;
    height: 70px;
    background: #e5857c url(${backImg}) 22% center no-repeat;
    background-size: 70px 70px;

    .title {
      display: inline;
      width: 20%;

      font-family: "Nanum Gothic Bold";

      font-size: 19px;

      margin-left: 4%;
      height: 70px;
      line-height: 70px;
      color: #fff;
    }
    .value {
      position: absolute;
      margin-left: 10%;
      top: 25%;
      width: 65%;
      display: inline-block;
      height: 20px;
      line-height: 18px;
      font-family: "koPubWorld DotumB";
      font-size: 13px;
      color: #fff;
      white-space: pre-line;
      word-break: keep-all;
    }

    @media (max-width: 320px) {
      .title {
        ${(style) => {
          if (name === "센터소개") {
            return " width: 15%; font-size: 16px;";
          } else if (name === "돌봄서비스") {
            return " width: 15%; font-size: 15px;";
          } else if (name === "공지사항") {
            return " width: 15%; font-size: 16px;";
          } else if (name === "자료실") {
            return " width: 15%; font-size: 16px;";
          } else if (name === "일반자료실" || name === "포토갤러리") {
            return " width: 15%; font-size: 15px;";
          } else if (name === "사이트맵") {
            return " width: 15%; font-size: 16px;";
          }
        }}
      }
      .value {
        ${(style) => {
          if (name === "센터소개") {
            return " margin-left: 7%; width: 61%; font-size: 8px; line-height: 15px; top: 20%; white-space: initial;";
          } else if (name === "사이트맵") {
            return " margin-left: 7%; width: 61%; font-size: 8px; line-height: 15px; top: 20%; white-space: initial;";
          } else if (name === "돌봄서비스") {
            return " margin-left: 5%; width: 61%; font-size: 8px; line-height: 15px; top: 30%; white-space: initial;";
          } else if (name === "공지사항") {
            return " margin-left: 7%; width: 61%; font-size: 8px; line-height: 15px; top: 20%; white-space: initial;";
          } else if (name === "자료실") {
            return " margin-left: 15%; width: 63%; font-size: 8px; line-height: 15px; top: 20%; white-space: initial;";
          } else if (name === "일반자료실" || name === "포토갤러리") {
            return " margin-left: 7%; width: 63%; font-size: 8px; line-height: 15px; top: 20%; white-space: initial;";
          }
        }}
      }
    }
    @media (min-width: 320px) {
      .title {
        ${(style) => {
          if (name === "센터소개") {
            return " width: 20%; font-size: 17px;";
          } else if (name === "사이트맵") {
            return " width: 20%; font-size: 17px;";
          } else if (name === "돌봄서비스") {
            return " width: 15%; font-size: 16px;";
          } else if (name === "공지사항") {
            return " width: 20%; font-size: 17px;";
          } else if (name === "자료실") {
            return " width: 20%; font-size: 17px;";
          } else if (name === "일반자료실" || name === "포토갤러리") {
            return " width: 15%; font-size: 16px;";
          }
        }}
      }
      .value {
        ${(style) => {
          if (name === "센터소개") {
            return " margin-left: 5%; width: 68%; font-size: 10px; line-height: 15px; top: 30%; white-space: initial;";
          } else if (name === "사이트맵") {
            return " margin-left: 5%; width: 68%; font-size: 10px; line-height: 15px; top: 30%; white-space: initial;";
          } else if (name === "돌봄서비스") {
            return " margin-left: 5%; width: 61%; font-size: 10px; line-height: 15px; top: 30%; white-space: initial;";
          } else if (name === "공지사항") {
            return " margin-left: 7%; width: 68%; font-size: 10px; line-height: 15px; top: 30%; white-space: initial;";
          } else if (name === "자료실") {
            return " margin-left: 7%; width: 68%; font-size:10px; line-height: 15px; top: 30%; white-space: initial;";
          } else if (name === "일반자료실" || name === "포토갤러리") {
            return " margin-left: 7%; width: 68%; font-size: 10px; line-height: 15px; top: 30%; white-space: initial;";
          }
        }}
      }
    }
    @media (min-width: 375px) {
      .title {
        width: 20%;

        font-size: 19px;
      }
      .value {
        margin-left: 10%;
        width: 62%;
        font-size: 11px;
        line-height: 17px;
        top: 25%;
      }
    }

    @media (min-width: 425px) {
      .title {
        width: 20%;

        font-size: 20px;
      }
      .value {
        margin-left: 10%;
        width: 60%;
        font-size: 12px;
        line-height: 20px;
        top: 23%;
        ${(style) => {
          if (name === "돌봄서비스") {
            return " margin-left: 10%; width: 60%; font-size: 12px; line-height: 20px; top: 35%; white-space: initial;";
          }
        }}
      }
    }

    @media (min-width: 768px) {
      background: #e5857c url(${backImg}) 20% center no-repeat;
      background-size: 100px 70px;

      .title {
        width: 20%;

        font-size: 25px;
      }
      .value {
        margin-left: 15%;
        width: 60%;
        font-size: 16px;
      }
    }
  `;

  return (
    <StyledDiv>
      <h2 className="title">{name}</h2>
      <p className="value">{value}</p>
    </StyledDiv>
  );
}

export default SubTitle;
