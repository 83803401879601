import styles from "./Footerm.module.css";

function Footerm({ address, phoneNumber }) {
  //console.log("Mobile Footer 주소 : ", address);
  //console.log("Mobile Footer 전화번호 : ", phoneNumber);

  return (
    <div className={styles.Footer}>
      <p>{address}</p>/<p>대표 : 오병전</p>
      <p>사업자등록번호 : 784-82-00462</p>
      <p>전화번호 : {phoneNumber}</p>
      <p>팩스 : 02-2234-2229</p>
      <p>이메일 : happycare0302@naver.com</p>
      <p>Copyright@2004 중구행복돌봄센터 All right Reserved.</p>
    </div>
  );
}

export default Footerm;
