import styles from "./NoticePages.module.css";
import SubTitle from "../components/SubTitle";
import Item from "../components/Item";
import { Link } from "react-router-dom";
import Location from "../components/Location";
import SubTel from "../components/SubTel";
import { useState, useEffect, useCallback } from "react";
import useAsync from "../hooks/useAsync";
import { getNotices } from "../Api";
import { useParams } from "react-router-dom";
const LIMIT = 6;

function NoticePages() {
  const no = useParams().no;
  const name = "공지사항";
  const value =
    "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다.";
  const menu = "공지사항";
  const submenu = "";
  const [order, setOrder] = useState("createdAt");
  const [isLoading, loadingError, getNoticessAsync] = useAsync(getNotices);
  const [items, setItems] = useState([]);
  const linked = "/notice";

  const handleLoad = useCallback(
    async (options) => {
      const result = await getNoticessAsync(options);
      //   console.log("확인");
      if (!result) return;

      const datas = result;
      //   console.log(datas);
      if (options.offset === 0) {
        setItems(datas);
      } else {
        setItems((prevItems) => [...prevItems, ...datas]);
      }
      //   setHasNext(paging.hasNext);
      setOrder("createdAt");
    },

    [getNoticessAsync]
  );

  useEffect(() => {
    handleLoad({ order, offset: 0, limit: LIMIT });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(items);

  return (
    <div className={styles.Notice}>
      <div className={styles.left}>
        <SubTitle name={name} value={value} />

        <ol className={styles.depth2} id="0">
          <li>
            <Link to="/notice" id={styles.menu}>
              공지사항
            </Link>
          </li>
        </ol>
        <SubTel height="1" />
      </div>
      <div id={styles.right}>
        <Location menu={menu} submenu={submenu} link={linked} />
        <h2>{menu}</h2>
        <div className={styles.Container}>
          {!isLoading && <Item items={items} no={no} order={linked} />}
        </div>

        {loadingError?.message && <span>{loadingError.message}</span>}
      </div>
    </div>
  );
}

export default NoticePages;
