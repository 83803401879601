import styles from "./MainPage.module.css";
import { Link } from "react-router-dom";
import Preview from "../components/Preview";
import { getDatas, getNotices, getPhotos } from "../../Api";
function MainPage() {
  return (
    <div className={styles.Main}>
      <div className={styles.image}></div>
      <p className={styles.sub}>도움의 손길이 필요할 때,</p>
      <p className={styles.sub2}>
        <span className={styles.blue}>중구</span>
        <span className={styles.red}>행복</span>
        <span className={styles.blue}>돌봄센터</span>가
      </p>
      <p className={styles.sub}> 함께 하겠습니다.</p>

      {/* 서비스 안내 */}
      <div id={styles.ctn2_quick}>
        <h3 className={styles.ctn_title}>서비스안내</h3>
        <p className={styles.ctn2_txt}>중구행복돌봄센터 서비스안내입니다.</p>
        <ul className={styles.care_list}>
          <li className={styles.sv1}>
            <Link to="/care/1"></Link>
          </li>
          <li className={styles.sv2}>
            <Link to="/care/2"></Link>
          </li>
          <li className={styles.sv3}>
            <Link to="/care/3"></Link>
          </li>
          <li className={styles.sv4}>
            <Link to="/care/4"></Link>
          </li>
        </ul>
      </div>

      {/* 서비스문의 */}

      <div id={styles.ctn2_tel}>
        <h3 className={styles.ctn_title2}>서비스문의</h3>
        <p className={styles.ctn3_txt}>
          전화주시면 친절히 섬심 성의껏 답변해 드립니다.
        </p>
        <p className={styles.ctn3_tel1}>&nbsp;02-3789-2229</p>
        <p className={styles.ctn3_tel2}>
          팩스 : 02-2234-2229 | 이메일 : happycare0302@naver.com
        </p>
      </div>

      {/* 공지사항 */}

      <div id={styles.Tabbox2}>
        <h3 className={styles.title}>공지사항</h3>

        <Preview address="/notice" getdata={getNotices} isPhoto={false} />
      </div>

      <div id={styles.Tabbox2}>
        <h3 className={styles.title}>일반자료실</h3>

        <Preview address="/data" getdata={getDatas} isPhoto={false} />
      </div>

      <div id={styles.Tabbox2}>
        <h3 className={styles.title}>포토갤러리</h3>

        <Preview address="/photo" getdata={getPhotos} isPhoto={true} />
      </div>
    </div>
  );
}

export default MainPage;
