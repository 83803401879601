import styles from "./Container.module.css";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import classNames from "classnames";

function Container() {
  const [isHovering, setIsHovering] = useState(true);

  const element = useRef(null);
  const menus = useRef([]);

  const handleMouseOver = (e) => {
    const id = e.target.id;
    element.current.style.height = "320px";
    // console.log(e.target);
    try {
      menus.current[Number(id)].style.background = "white";
      menus.current[Number(id)].style.color = "#e5857c";
    } catch (e) {
      console.error(e);
    }
  };

  const handleMouseOut = (e) => {
    const id = e.target.id;
    element.current.style.height = "52px";
    // console.log(e.target);
    try {
      menus.current[Number(id)].style.background = "#e5857c";
      menus.current[Number(id)].style.color = "white";
    } catch (e) {
      console.error(e);
    }
    setIsHovering(false);
  };

  return (
    <div className={styles.Container} ref={element}>
      <h2 className={styles.hidden}>메인메뉴</h2>
      <input type="hidden" id="menu" name="menu" value="0" />
      <input type="hidden" id="submenu" name="submenu" value="" />
      <input type="hidden" id="leftsubmenu" name="submenu" value="" />
      <nav
        id={styles.navigation}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <ol className={styles.depth1} id="2">
          <li className={styles.bd_line_left} id="0">
            <Link
              to="/introduce/1"
              id="0"
              ref={(el) => (menus.current[0] = el)}
            >
              센터소개
            </Link>

            <ol className={styles.depth2} id="0">
              <li>
                <Link to="/introduce/1" id="0">
                  인사말
                </Link>
              </li>
              <li>
                <Link to="/introduce/2" id="0">
                  연혁
                </Link>
              </li>
              <li>
                <Link to="/introduce/3" id="0">
                  조직안내
                </Link>
              </li>
              <li>
                <Link to="/introduce/4" id="0">
                  오시는길
                </Link>
              </li>
            </ol>
          </li>
          <li className={styles.m_bg} id="1">
            <Link to="/care/1" id="1" ref={(el) => (menus.current[1] = el)}>
              돌봄서비스
            </Link>

            <ol className={styles.depth2} id="1">
              <li>
                <Link to="/care/1" id="1">
                  장애인활동지원서비스
                </Link>
              </li>
              <li>
                <Link to="/care/2" id="1">
                  노인장기요양서비스
                </Link>
              </li>
              <li>
                <Link to="/care/3" id="1">
                  가사간병방문지원서비스
                </Link>
              </li>
              <li>
                <Link to="/care/4" id="1">
                  돌봄SOS서비스
                </Link>
              </li>
              <li>
                <Link to="/care/5" id="1">
                  서비스 상담 문의
                </Link>
              </li>
            </ol>
          </li>
          <li className={styles.m_bg} id="2">
            <Link to="/notice" id="2" ref={(el) => (menus.current[2] = el)}>
              공지사항
            </Link>
            {isHovering && <ol className={styles.depth2} id="2"></ol>}
          </li>

          <li className={classNames(styles.bd_line_right, styles.m_bg)} id="3">
            <Link to="/data" id="3" ref={(el) => (menus.current[3] = el)}>
              자료실
            </Link>

            <ol className={styles.depth2} id="3">
              <li>
                <Link to="/data" id="3">
                  일반자료실
                </Link>
              </li>
              <li>
                <Link to="/photo" id="3">
                  포토갤러리
                </Link>
              </li>
            </ol>
          </li>
        </ol>
      </nav>
    </div>
  );
}

export default Container;
