import styles from "./ItemList.module.css";
import { useState, useEffect } from "react";
import btnimg from "../assets/btn.png";
import PageNav from "./PageNav";
import { Link } from "react-router-dom";
import image from "../assets/attach.png";
import moment from "moment";
//선언하지 않아도, 디바이스 혹은 locale의 시간을 불러온다.
import "moment/locale/ko"; //대한민국

function formatDate(value) {
  const date = moment(value);
  return `${date.year()}. ${date.month() + 1}. ${date.date()}`;
}

function ItemListItem({ item, order, index }) {
  // console.log(index);
  //   console.log(order + item.id);
  return (
    <div className={styles.ItemListItem}>
      <div>
        {item.isPublic && (
          <>
            <p className={styles.important}>공지</p>
          </>
        )}
        {!item.isPublic && (
          <>
            <p>{index}</p>
          </>
        )}

        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link to={order + item.id}>
            {item.title} &nbsp;
            {item.files && (
              <>
                <img src={image}></img>
              </>
            )}
          </Link>
        </p>
        <p>{item.writer.username}</p>
        <p>{formatDate(item.createdAt)}</p>
      </div>
    </div>
  );
}

// console.log(
//     Object.keys(sortedItems).map((key) => {
//       return sortedItems[key];
//     })
//   );

function ItemList({ items, order }) {
  let length = items.length;
  const offset = 10;
  let count = 0;

  Object.keys(items).map((key) => {
    if (items[key].isPublic === true) {
      count = count + 1;
    }
  });
  //   console.log(count);
  const [current_page, set_Current_Page] = useState(1);
  const [page, setPage] = useState(
    Math.ceil((items.length - offset) / (offset - count)) + 1
  );
  //   console.log(items);
  let cnt = 0;

  const [selected, setSelected] = useState(1);
  const [searchItem, setSearchItem] = useState(items);
  const [isSearch, setIsSearch] = useState(false);

  const findItem = (search, selected, items) => {
    console.log(`검색단어 : ${search}`);
    console.log(`select : ${selected}`);
    console.log(items);

    const item = [];
    Object.keys(items).map((key) => {
      if (items[key].isPublic === true) {
        item.push(items[key]);
      }
    });

    if (selected === 1) {
      Object.keys(items).map((key) => {
        if (
          items[key].title.includes(search) ||
          items[key].summary.includes(search)
        ) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
      });
    } else if (selected === 2) {
      Object.keys(items).map((key) => {
        if (items[key].title.includes(search)) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
      });
    } else if (selected === 3) {
      Object.keys(items).map((key) => {
        if (items[key].summary.includes(search)) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
      });
    }

    return item;
  };

  const handleChange = (num) => {
    set_Current_Page(Number(num));
  };

  const handleOnClick = () => {
    const text = document.getElementById("search");
    let item;
    setIsSearch(true);

    if (text.value === "") setIsSearch(false);
    item = findItem(text.value, selected, items);
    if (item) {
      setPage(Math.ceil((item.length - offset) / (offset - count)) + 1);

      length = item.length;
      setSearchItem(item);
    }
  };

  const handleSelected = (e) => {
    const value = e.target.value;

    if (value == "전체") setSelected(1);
    else if (value == "제목") setSelected(2);
    else if (value == "내용") setSelected(3);
  };

  const handleOnkeyPress = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      handleOnClick();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefult();
  };

  useEffect(() => {
    setPage(
      (prevItem) => Math.ceil((items.length - offset) / (offset - count)) + 1
    );
  }, [items]);

  return (
    <>
      <div className={styles.search}>
        <form onSubmit={handleSubmit}>
          <select onChange={handleSelected}>
            <option>전체</option>
            <option>제목</option>
            <option>내용</option>
          </select>
          <input type="text" id="search" onKeyDown={handleOnkeyPress}></input>
          <input type="text" className={styles.hidden} />
          <button type="button">
            <img src={btnimg} onClick={handleOnClick}></img>
          </button>
        </form>
      </div>

      {!isSearch && (
        <>
          <h3 className={styles.state}>
            전체 게시물 {length}개, 현재 {current_page}페이지 / 전체 {page}
            페이지
          </h3>

          <ul className={styles.table}>
            <ul className={styles.colum}>
              <li>번호</li>
              <li>제목</li>
              <li>작성자</li>
              <li>등록일</li>
            </ul>
            {Object.keys(items).map((key, index) => {
              if (items[key].isPublic === true) {
                cnt = cnt + 1;
                return (
                  <li key={items[key].id}>
                    <ItemListItem
                      item={items[key]}
                      order={order}
                      index={length - index}
                    />
                  </li>
                );
              }

              if (index - cnt < offset - cnt) {
                if (current_page === 1) {
                  return (
                    <li key={items[key].id}>
                      <ItemListItem
                        item={items[key]}
                        order={order}
                        index={length - index}
                      />
                    </li>
                  );
                }
              } else if (
                index - cnt >= (offset - cnt) * (current_page - 1) &&
                index - cnt < (offset - cnt) * current_page
              ) {
                if (current_page !== 1) {
                  return (
                    <li key={items[key].id}>
                      <ItemListItem
                        item={items[key]}
                        order={order}
                        index={length - index}
                      />
                    </li>
                  );
                }
              } else return;
            })}
          </ul>
          <PageNav cnt={page} page={current_page} change={handleChange} />
        </>
      )}
      {isSearch && (
        <>
          <h3 className={styles.state}>
            전체 게시물 {length}개, 현재 {current_page}페이지 / 전체 {page}
            페이지
          </h3>

          <ul className={styles.table}>
            <ul className={styles.colum}>
              <li>번호</li>
              <li>제목</li>
              <li>작성자</li>
              <li>등록일</li>
            </ul>
            {Object.keys(searchItem).map((key, index) => {
              if (searchItem[key].isPublic === true) {
                cnt = cnt + 1;
                return (
                  <li key={searchItem[key].id}>
                    <ItemListItem
                      item={searchItem[key]}
                      order={order}
                      index={searchItem.length - index}
                    />
                  </li>
                );
              }

              if (index - cnt < offset - cnt) {
                if (current_page === 1) {
                  return (
                    <li key={searchItem[key].id}>
                      <ItemListItem
                        item={searchItem[key]}
                        order={order}
                        index={searchItem.length - index}
                      />
                    </li>
                  );
                }
              } else if (
                index - cnt >= (offset - cnt) * (current_page - 1) &&
                index - cnt < (offset - cnt) * current_page
              ) {
                if (current_page !== 1) {
                  return (
                    <li key={searchItem[key].id}>
                      <ItemListItem
                        item={searchItem[key]}
                        order={order}
                        index={searchItem.length - index}
                      />
                    </li>
                  );
                }
              } else return;
            })}
          </ul>
          <PageNav cnt={page} page={current_page} change={handleChange} />
        </>
      )}
    </>
  );
}

export default ItemList;
