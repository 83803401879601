/* global kakao */

import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Map.module.css";

const { kakao } = window;

const Map = () => {
  useEffect(() => {
    let container = document.getElementById("map");

    let options = {
      center: new window.kakao.maps.LatLng(37.5655, 127.018),
      level: 2,
    };

    let map = new window.kakao.maps.Map(container, options);

    let markerPosition = new kakao.maps.LatLng(37.565, 127.0176);

    let marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    var infowindow = new kakao.maps.InfoWindow({
      content:
        '<div style="width:150px;text-align:center;padding:px 0;">중구행복돌봄센터</div>',
    });
    marker.setMap(map);
    infowindow.open(map, marker);
    console.log("loading kakaomap");
  }, []);

  return (
    <div className={cn(styles.Map)}>
      <div className={cn(styles.MapContainer)} id="map"></div>
    </div>
  );
};

export default Map;
