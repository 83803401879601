import styles from "./MPage.module.css";
import SubTitle from "../components/SubTitle";
import ItemList from "../components/ItemList";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import useAsync from "../../hooks/useAsync";
import { Sort } from "../../utils/Sort";

const LIMIT = 6;
function MPage({ name, page, value, getData }) {
  const [order, setOrder] = useState("createdAt");
  const [isLoading, loadingError, getNoticessAsync] = useAsync(getData);
  const [items, setItems] = useState([]);
  const sortedItems = Sort(items.sort((a, b) => b["id"] - a["id"]));
  const [offset, setOffset] = useState(10);

  const handleLoad = useCallback(
    async (options) => {
      const result = await getNoticessAsync(options);
      //   console.log("확인");
      if (!result) return;

      const datas = result;
      //   console.log(datas);
      if (options.offset === 0) {
        setItems(datas);
      } else {
        setItems((prevItems) => [...prevItems, ...datas]);
      }
      //   setHasNext(paging.hasNext);
    },
    [getNoticessAsync]
  );
  // console.log(sortedItems);
  useEffect(() => {
    handleLoad({ order, offset: 0, limit: LIMIT });
    if (page === "/photo/") setOffset(6);
    else setOffset(10);
  }, [page]);

  const activeStyle = {
    color: "#E5857C",
    fontSize: "15px",
  };
  const activeStyle2 = {
    backgroundColor: "#FFF",
  };
  return (
    <div className={styles.Notice}>
      {page === "/notice/" && <SubTitle name={name} value={value} />}
      {!(page === "/notice/") && <SubTitle name={"자료실"} value={value} />}

      <ol className={styles.depth2} id="0">
        {page === "/notice/" && (
          <li className={styles.noti} style={activeStyle2}>
            <Link to="/notice" id={styles.menu} style={activeStyle}>
              공지사항
            </Link>
          </li>
        )}
        {!(page === "/notice/") && (
          <>
            <li style={page === "/data/" ? activeStyle2 : undefined}>
              <Link
                to="/data"
                id={styles.menu}
                style={page === "/data/" ? activeStyle : undefined}
              >
                일반 자료실
              </Link>
            </li>

            <li style={page === "/photo/" ? activeStyle2 : undefined}>
              <Link
                to="/photo"
                id={styles.menu}
                style={page === "/photo/" ? activeStyle : undefined}
              >
                포토갤러리
              </Link>
            </li>
          </>
        )}
      </ol>
      <h2 className={styles.subtitle}>{name}</h2>

      <ItemList items={sortedItems} order={page} offset={offset} />
      {loadingError?.message && <span>{loadingError.message}</span>}
    </div>
  );
}

export default MPage;
