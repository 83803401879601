import styles from "./ItemList.module.css";
import { useState, useEffect } from "react";
import btnimg from "../../assets/btn.png";
import PageNav from "./PageNav";
import { Link } from "react-router-dom";
import image from "../../assets/attach.png";

function formatDate(value) {
  const date = new Date(value);
  return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`;
}

function ImageListItem({ item, order }) {
  //   console.log(order + item.id);

  const style = {
    background: `#f4f4f4 url('${item.thumb_files}')center center no-repeat`,
    backgroundSize: "cover",
  };

  return (
    <div className={styles.ImageListItem}>
      <Link to={order + item.id}>
        <div>
          {!item.thumb_files && <div className={styles.image}></div>}
          {item.thumb_files && (
            <div className={styles.image2} style={style}></div>
          )}

          <p>{item.title} </p>
          <p>등록일 {formatDate(item.createdAt)}</p>
          <p>조회수 {item.hit}</p>
        </div>
      </Link>
    </div>
  );
}

function ItemListItem({ item, order, index }) {
  //   console.log(order + item.id);
  return (
    <div className={styles.ItemListItem}>
      <div>
        {item.isPublic && (
          <>
            <p className={styles.important}>공지</p>
          </>
        )}
        {!item.isPublic && (
          <>
            <p>{index}</p>
          </>
        )}

        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link to={order + item.id}>
            {item.title} &nbsp;
            {item.files && (
              <>
                <img alt="files" src={image}></img>
              </>
            )}
          </Link>
        </p>
        <p>작성자</p>
        <p>{item.writer.username}</p>
        <p>등록일</p>
        <p>{formatDate(item.createdAt)}</p>
      </div>
    </div>
  );
}

// console.log(
//     Object.keys(sortedItems).map((key) => {
//       return sortedItems[key];
//     })
//   );

function ItemList({ items, order, offset }) {
  let length = items.length;
  let count = 0;
  Object.keys(items).map((key) => {
    if (items[key].isPublic === true) {
      count = count + 1;
    }
    return undefined;
  });
  //   console.log(count);
  const [current_page, set_Current_Page] = useState(1);
  const [page, setPage] = useState(
    Math.ceil((items.length - offset) / (offset - count)) + 1
  );
  //   console.log(items);
  let cnt = 0;

  const [selected, setSelected] = useState(1);
  const [searchItem, setSearchItem] = useState(items);
  const [isSearch, setIsSearch] = useState(false);

  const findItem = (search, selected, items) => {
    // console.log(`검색단어 : ${search}`);
    // console.log(`select : ${selected}`);
    // console.log(items);

    const item = [];
    Object.keys(items).map((key) => {
      if (items[key].isPublic === true) {
        item.push(items[key]);
      }
      return undefined;
    });

    if (selected === 1) {
      Object.keys(items).map((key) => {
        if (
          items[key].title.includes(search) ||
          items[key].summary.includes(search)
        ) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
        return undefined;
      });
    } else if (selected === 2) {
      Object.keys(items).map((key) => {
        if (items[key].title.includes(search)) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
        return undefined;
      });
    } else if (selected === 3) {
      Object.keys(items).map((key) => {
        if (items[key].summary.includes(search)) {
          if (items[key].isPublic === false) item.push(items[key]);
        }
        return undefined;
      });
    }

    return item;
  };

  const handleChange = (num) => {
    set_Current_Page(Number(num));
  };

  const handleOnClick = () => {
    const text = document.getElementById("search");
    let item;
    setIsSearch(true);

    if (text.value === "") setIsSearch(false);
    item = findItem(text.value, selected, items);
    if (item) {
      setPage(Math.ceil((item.length - offset) / (offset - count)) + 1);

      length = item.length;
      setSearchItem(item);
    }
  };

  const handleSelected = (e) => {
    const value = e.target.value;

    if (value === "전체") setSelected(1);
    else if (value === "제목") setSelected(2);
    else if (value === "내용") setSelected(3);
  };

  const handleOnkeyPress = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      handleOnClick();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefult();
  };

  useEffect(() => {
    setPage(
      (prevItem) => Math.ceil((items.length - offset) / (offset - count)) + 1
    );
  }, [items, count, offset]);
  return (
    <div className={styles.ItemList}>
      <div className={styles.search}>
        <form onSubmit={handleSubmit}>
          <select onChange={handleSelected}>
            <option>전체</option>
            <option>제목</option>
            <option>내용</option>
          </select>
          <input type="text" id="search" onKeyDown={handleOnkeyPress}></input>
          <input type="text" className={styles.hidden} />
          <button type="button">
            <img src={btnimg} alt="button" onClick={handleOnClick}></img>
          </button>
        </form>
      </div>

      {!isSearch && (
        <>
          <ul className={styles.table}>
            {Object.keys(items).map((key, index) => {
              if (items[key].isPublic === true) {
                cnt = cnt + 1;
                return (
                  <li key={items[key].id}>
                    {}
                    <ItemListItem
                      item={items[key]}
                      order={order}
                      index={length - index}
                    />
                  </li>
                );
              }

              if (index - cnt < offset - cnt) {
                if (current_page === 1) {
                  return (
                    <li key={items[key].id}>
                      {order === "/photo/" && (
                        <ImageListItem item={items[key]} order={order} />
                      )}
                      {!(order === "/photo/") && (
                        <ItemListItem
                          item={items[key]}
                          order={order}
                          index={length - index}
                        />
                      )}
                    </li>
                  );
                }
              } else if (
                index - cnt >= (offset - cnt) * (current_page - 1) &&
                index - cnt < (offset - cnt) * current_page
              ) {
                if (current_page !== 1) {
                  return (
                    <li key={items[key].id}>
                      {order === "/photo/" && (
                        <ImageListItem item={items[key]} order={order} />
                      )}
                      {!(order === "/photo/") && (
                        <ItemListItem
                          item={items[key]}
                          order={order}
                          index={length - index}
                        />
                      )}
                    </li>
                  );
                }
              } else return undefined;
              return undefined;
            })}
          </ul>
          <PageNav cnt={page} page={current_page} change={handleChange} />
        </>
      )}
      {isSearch && (
        <>
          <ul className={styles.table}>
            {Object.keys(searchItem).map((key, index) => {
              if (searchItem[key].isPublic === true) {
                cnt = cnt + 1;
                return (
                  <li key={searchItem[key].id}>
                    {order === "/photo/" && (
                      <ImageListItem item={searchItem[key]} order={order} />
                    )}
                    {!(order === "/photo/") && (
                      <ItemListItem
                        item={searchItem[key]}
                        order={order}
                        index={searchItem.length - index}
                      />
                    )}
                  </li>
                );
              }

              if (index - cnt < offset - cnt) {
                if (current_page === 1) {
                  return (
                    <li key={searchItem[key].id}>
                      {order === "/photo/" && (
                        <ImageListItem item={searchItem[key]} order={order} />
                      )}
                      {!(order === "/photo/") && (
                        <ItemListItem
                          item={searchItem[key]}
                          order={order}
                          index={searchItem.length - index}
                        />
                      )}
                    </li>
                  );
                }
              } else if (
                index - cnt >= (offset - cnt) * (current_page - 1) &&
                index - cnt < (offset - cnt) * current_page
              ) {
                if (current_page !== 1) {
                  return (
                    <li key={searchItem[key].id}>
                      {order === "/photo/" && (
                        <ImageListItem item={searchItem[key]} order={order} />
                      )}
                      {!(order === "/photo/") && (
                        <ItemListItem
                          item={searchItem[key]}
                          order={order}
                          index={searchItem.length - index}
                        />
                      )}
                    </li>
                  );
                }
              } else return undefined;

              return undefined;
            })}
          </ul>
          <PageNav cnt={page} page={current_page} change={handleChange} />
        </>
      )}
    </div>
  );
}

export default ItemList;
