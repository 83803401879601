import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";

function Sidebar() {
  const [isHovering, setIsHovering] = useState([false, false, false, false]);

  const handleOnClick = (e) => {
    const value = e.target.value;

    if (value === "1") {
      if (isHovering[0])
        setIsHovering([false, isHovering[1], isHovering[2], isHovering[3]]);
      else setIsHovering([true, isHovering[1], isHovering[2], isHovering[3]]);
    } else if (value === "2") {
      if (isHovering[1])
        setIsHovering([isHovering[0], false, isHovering[2], isHovering[3]]);
      else setIsHovering([isHovering[0], true, isHovering[2], isHovering[3]]);
    } else if (value === "3") {
      if (isHovering[2])
        setIsHovering([isHovering[0], isHovering[1], false, isHovering[3]]);
      else setIsHovering([isHovering[0], isHovering[1], true, isHovering[3]]);
    } else if (value === "4") {
      if (isHovering[3])
        setIsHovering([isHovering[0], isHovering[1], isHovering[2], false]);
      else setIsHovering([isHovering[0], isHovering[1], isHovering[2], true]);
    }
  };

  return (
    <>
      <ol className={styles.depth1} id="2">
        <li className={styles.m_bg} id="0">
          <button value="1" onClick={handleOnClick}>
            센터소개
          </button>
          {isHovering[0] && (
            <ol className={styles.depth2} id="0">
              <li>
                <Link to="/introduce/1" id="0">
                  인사말
                </Link>
              </li>
              <li>
                <Link to="/introduce/2" id="0">
                  연혁
                </Link>
              </li>
              <li>
                <Link to="/introduce/3" id="0">
                  조직안내
                </Link>
              </li>
              <li>
                <Link to="/introduce/4" id="0">
                  오시는길
                </Link>
              </li>
            </ol>
          )}
        </li>
        <li className={styles.m_bg} id="1">
          <button value="2" onClick={handleOnClick}>
            돌봄서비스
          </button>

          {isHovering[1] && (
            <ol className={styles.depth2} id="1">
              <li>
                <Link to="/care/1" id="1">
                  장애인활동지원서비스
                </Link>
              </li>
              <li>
                <Link to="/care/2" id="1">
                  노인장기요양서비스
                </Link>
              </li>
              <li>
                <Link to="/care/3" id="1">
                  가사간병방문지원서비스
                </Link>
              </li>
              <li>
                <Link to="/care/4" id="1">
                  돌봄SOS서비스
                </Link>
              </li>
              <li>
                <Link to="/care/5" id="1">
                  서비스 상담 문의
                </Link>
              </li>
            </ol>
          )}
        </li>
        <li className={styles.m_bg} id="2">
          <button value="3" onClick={handleOnClick}>
            공지사항
          </button>
          {isHovering[2] && (
            <ol className={styles.depth2} id="3">
              <li>
                <Link to="/notice" id="3">
                  공지사항
                </Link>
              </li>
            </ol>
          )}
        </li>

        <li className={styles.m_bg} id="3">
          <button className={styles.last} value="4" onClick={handleOnClick}>
            자료실
          </button>
          {isHovering[3] && (
            <ol className={styles.depth2} id="3">
              <li>
                <Link to="/data" id="3">
                  일반자료실
                </Link>
              </li>
              <li>
                <Link to="/photo" id="3">
                  포토갤러리
                </Link>
              </li>
            </ol>
          )}
        </li>

        <li className={styles.banner}>
          <Link to="/sitemap" title="사이트맵">
            사이트맵
          </Link>

          <a
            href="https://www.seempower.kr/"
            target="_blank"
            title="에스이임파워협동조합 홈페이지로 이동"
          >
            협동조합 바로가기
          </a>
        </li>
      </ol>
    </>
  );
}

export default Sidebar;
