import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Item.module.css";
import { update } from "../Api";

function formatDate(value) {
  const date = new Date(value);
  return `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`;
}

function Item({ items, no, order }) {
  // console.log(no);
  // console.log(items);
  const [isdiplay, setDisplay] = useState(false);

  const [item, setItem] = useState();

  // console.log(item);
  // Object.keys(items).map((key) => {
  //   if (
  //     items[key].title.includes(search) ||
  //     items[key].summary.includes(search)
  //   ) {
  //     if (items[key].isPublic === false) item.push(items[key]);
  //   }
  // });
  // if (items) {
  //   console.log("test");
  //   items.map((key) => {
  //     if (key.id === Number(no)) console.log(key);
  //   });
  // }

  const fileName = (files) => {
    try {
      return files.split("/").at(-1);
    } catch {
      return "파일 이름";
    }
  };

  const handleDownload = () => {
    fetch(item.files, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName(decodeURI(item.files));
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
        // setOpen(false);
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  };

  const handleDisplay = () => {
    if (isdiplay === false) {
      setDisplay((prevState) => {
        return true;
      });
    } else {
      setDisplay((prevState) => {
        return false;
      });
    }
  };
  useEffect(() => {
    items.map((key) => {
      if (key.id === Number(no)) {
        setItem((prevItem) => key);
        // console.log("입력");
        // console.log(key);
        update(key, no);
      }
      return 1;
    });
  }, [items, no]);
  return (
    <div className={styles.Item}>
      {item && (
        <>
          <div className={styles.title}>
            <h1>{item.title}</h1>
          </div>
          <div className={styles.info}>
            <p>작성자</p>
            <p>{item.writer.username}</p>
            <p>등록일</p>
            <p>{formatDate(item.createdAt)}</p>
          </div>
          <div className={styles.file}>
            <p>첨부파일</p>
            {item.files && (
              <>
                {order !== "/photo" && (
                  <button onClick={handleDownload}>
                    {fileName(decodeURI(item.files))}
                  </button>
                )}
                {order === "/photo" && (
                  <button onClick={handleDisplay}>
                    {fileName(decodeURI(item.files))}
                  </button>
                )}
              </>
            )}
            {!item.files && <p>첨부파일 없음</p>}
          </div>

          <div className={styles.content}>
            {isdiplay && (
              <div className={styles.photo}>
                <img src={decodeURI(item.files)} alt="이미지"></img>
              </div>
            )}

            <div dangerouslySetInnerHTML={{ __html: item.summary }}></div>
          </div>

          <Link to={order}>목록보기</Link>
        </>
      )}
    </div>
  );
}

export default Item;
