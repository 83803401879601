import styles from "./NoticePage.module.css";
import SubTitle from "../components/SubTitle";
import ItemList from "../components/ItemList";
import { Link } from "react-router-dom";
import Location from "../components/Location";
import SubTel from "../components/SubTel";
import { useState, useEffect, useCallback } from "react";
import useAsync from "../hooks/useAsync";
import { getNotices } from "../Api";
import { Sort } from "../utils/Sort";

const LIMIT = 6;

function NoticePage() {
  const name = "공지사항";
  const value =
    "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다.";
  const menu = "공지사항";
  const submenu = "";

  const [order, setOrder] = useState("createdAt");
  const [isLoading, loadingError, getNoticessAsync] = useAsync(getNotices);
  const [items, setItems] = useState([]);
  const sortedItems = Sort(items.sort((a, b) => b["id"] - a["id"]));

  const linked = "/notice";

  const handleLoad = useCallback(
    async (options) => {
      const result = await getNoticessAsync(options);
      //   console.log("확인");
      if (!result) return;

      const datas = result;
      //   console.log(datas);
      if (options.offset === 0) {
        setItems(datas);
      } else {
        setItems((prevItems) => [...prevItems, ...datas]);
      }
      setOrder("createdAt");
      //   setHasNext(paging.hasNext);
    },
    [getNoticessAsync]
  );
  // console.log(sortedItems);
  useEffect(() => {
    handleLoad({ order, offset: 0, limit: LIMIT });
  }, []);

  return (
    <div className={styles.Notice}>
      <div className={styles.left}>
        <SubTitle name={name} value={value} />

        <ol className={styles.depth2} id="0">
          <li>
            <Link to="/notice" id={styles.menu}>
              공지사항
            </Link>
          </li>
        </ol>
        <SubTel height="1" />
      </div>
      <div id={styles.right}>
        <Location menu={menu} submenu={submenu} link={linked} />
        <h2>{menu}</h2>
        <div className={styles.Container}>
          <ItemList items={sortedItems} order="/notice/" />
        </div>

        {loadingError?.message && <span>{loadingError.message}</span>}
      </div>
    </div>
  );
}

export default NoticePage;
