import styles from "./NavHeader.module.css";
import { Link } from "react-router-dom";

function NavHeader() {
  return (
    <div className={styles.NavHeader}>
      <h1 className={styles.top}>
        <Link
          to="/"
          title="홈 바로가기"
          style={{ width: "100%", height: "55px", display: "block" }}
        >
          <span className={styles.hidden}>중구행복돌봄센터</span>
        </Link>
      </h1>
      <dl id={styles.globalmenu}>
        <dt>
          <strong>유틸메뉴</strong>
        </dt>
        <dd>
          <Link to="/">홈으로</Link>
        </dd>
        <dd>
          <Link to="/sitemap" title="사이트맵">
            사이트맵
          </Link>
        </dd>
        <dd>
          <a
            href="https://www.seempower.kr/"
            target="_blank"
            title="에스이임파워협동조합 홈페이지로 이동"
          >
            협동조합 바로가기
          </a>
        </dd>
      </dl>
    </div>
  );
}

export default NavHeader;
