import styles from "./Location.module.css";
import { Link } from "react-router-dom";

function Location({ menu, submenu, link }) {
  const arrow = " > ";

  return (
    <div id={styles.location}>
      <p>
        <Link className={styles.path_home} to="/">
          <span className={styles.hidden}>홈바로가기</span>
        </Link>
        {arrow}

        {submenu !== "" && (
          <>
            <Link to={link}>{menu}</Link>

            {arrow}
            <strong className={styles.subm}>{submenu}</strong>
          </>
        )}

        {submenu === "" && (
          <>
            <Link to={link}>
              <strong className={styles.mainm}>{menu}</strong>
            </Link>
          </>
        )}
      </p>
    </div>
  );
}

export default Location;
