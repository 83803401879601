import styles from "./CareList.module.css";
import styled from "styled-components";

function CareList({ List, no }) {
  //   console.log(List["3"]["value"][0].split(":")[1]);
  //   console.log(List["3"]["value"][0].split(": ")[1]);

  const StyledDiv = styled.div`
    float: left;
    margin-left: 5%;
    margin-top: -3%;

    width: 90%;
    height: auto;
    font-family: "koPubWorld DotumB";

    @media (min-width: 320px) {
      margin-top: ${(margin) => {
        if (no === "1") {
          return "-1%";
        } else if (no === "2") {
          return "-6%";
        } else if (no === "3") {
          return "-1%";
        } else if (no === "4") {
          return "0%";
        }
      }};
    }
    @media (min-width: 375px) {
      margin-top: ${(margin) => {
        if (no === "1") {
          return "-3%";
        } else if (no === "2") {
          return "-8%";
        } else if (no === "3") {
          return "-3%";
        } else if (no === "4") {
          return "-2%";
        }
      }};
    }

    @media (min-width: 425px) {
    }

    @media (min-width: 768px) {
      margin-top: ${(margin) => {
        if (no === "1") {
          return "-5%";
        } else if (no === "2") {
          return "-8%";
        } else if (no === "3") {
          return "-5%";
        } else if (no === "4") {
          return "-5%";
        }
      }};
    }
  `;

  return (
    <StyledDiv className={styles.CareList}>
      {no !== "5" && (
        <>
          <h1 className={styles.name}>{List["0"]["name"]}</h1>
          <div className={styles.list1}>
            <div className={styles.list2}>
              <p>{List["0"]["value"]} </p>
            </div>
          </div>
          <h1 className={styles.name}>{List["1"]["name"]}</h1>
          {no !== "3" && (
            <div className={styles.list3}>
              <p>
                <span>・</span>
                {List["1"]["value"]}
              </p>
            </div>
          )}
          {no === "3" && (
            <div className={styles.list3}>
              <p>
                <span>・</span>
                {List["1"]["value"][0]}
              </p>
              <p>
                <span>・</span>
                {List["1"]["value"][1]}
              </p>
            </div>
          )}

          <h1 className={styles.name}>{List["2"]["name"]}</h1>
          {no !== "4" && (
            <div className={styles.list3}>
              <p>
                <span>・</span>
                {List["2"]["value"].split("\n")[0]}
              </p>
              {no === "1" && (
                <p className={styles.sub}>
                  <span>-</span>
                  {List["2"]["value"].split("\n")[1]}
                </p>
              )}
            </div>
          )}

          <h1 className={styles.name}>{List["3"]["name"]}</h1>
          <div className={styles.list3}>
            <p>
              <span>・</span>
              {List["3"]["value"][0].split(": ")[0]}
            </p>
            <p className={styles.sub}>
              <span>-</span>
              {List["3"]["value"][0].split(": ")[1]}
            </p>
            <p>
              <span>・</span>
              {List["3"]["value"][1].split(": ")[0]}
            </p>
            <p className={styles.sub}>
              <span>-</span>
              {List["3"]["value"][1].split(": ")[1]}
            </p>
            <p>
              <span>・</span>
              {List["3"]["value"][2].split(": ")[0]}
            </p>
            <p className={styles.sub}>
              <span>-</span>
              {List["3"]["value"][2].split(": ")[1]}
            </p>
            <p>
              <span>・</span>
              {List["3"]["value"][3].split(": ")[0]}
            </p>
            {no !== "1" && (
              <p className={styles.sub}>
                <span>-</span>
                {List["3"]["value"][3].split(": ")[1]}
              </p>
            )}

            {no === "4" && (
              <>
                <p>
                  <span>・</span>
                  {List["3"]["value"][4].split(": ")[0]}
                </p>
                <p className={styles.sub}>
                  <span>-</span>
                  {List["3"]["value"][4].split(": ")[1]}
                </p>
                <p>
                  <span>・</span>
                  {List["3"]["value"][5].split(": ")[0]}
                </p>
                <p className={styles.sub}>
                  <span>-</span>
                  {List["3"]["value"][5].split(": ")[1]}
                </p>
                <p>
                  <span>・</span>
                  {List["3"]["value"][6].split(": ")[0]}
                </p>
                <p className={styles.sub}>
                  <span>-</span>
                  {List["3"]["value"][6].split(": ")[1]}
                </p>
              </>
            )}
          </div>

          <h1 className={styles.name}>{List["4"]["name"]}</h1>
          {no !== "4" && (
            <div className={styles.list3}>
              {no !== "2" && (
                <p>
                  <span>・</span>
                  {List["4"]["value"]}
                </p>
              )}
              {no === "2" && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>소득수준</th>
                        <th>본인부담금</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>「국민기초생활보장법」에 따른 의료급여 수급자</td>
                        <td> 면제</td>
                      </tr>
                      <tr>
                        <td>일반</td>
                        <td>재가급여비용의 15%</td>
                      </tr>
                    </tbody>
                  </table>
                  <p> {List["4"]["value"]}</p>
                </div>
              )}
            </div>
          )}

          <h1 className={styles.name}>{List["5"]["name"]}</h1>
          <div className={styles.list3}>
            <p>
              <span>・</span>
              {List["5"]["value"]}
            </p>
          </div>
        </>
      )}
    </StyledDiv>
  );
}

export default CareList;
