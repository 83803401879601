import SubTitle from "../components/SubTitle";
import styles from "./Site.module.css";
import { Link } from "react-router-dom";

function Site() {
  const name = "사이트맵";
  const value =
    "중구행복돌봄센터는 함께하는 가치로 사람중심,\n상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다.";
  const submenu = "사이트맵";

  return (
    <div className={styles.Site}>
      <SubTitle name={name} value={value} />
      <h2 className={styles.subtitle}>{submenu}</h2>
      <div className={styles.Container}>
        <ol className={styles.depth1} id="2">
          <li className={styles.left} id="0">
            <div>
              <Link to="/introduce/1" id={styles.menu}>
                센터소개
              </Link>
            </div>

            <ol className={styles.depth2} id="0">
              <li>
                <Link to="/introduce/1" id="0">
                  인사말
                </Link>
              </li>
              <li>
                <Link to="/introduce/2" id="0">
                  연혁
                </Link>
              </li>
              <li>
                <Link to="/introduce/3" id="0">
                  조직안내
                </Link>
              </li>
              <li>
                <Link to="/introduce/4" id="0">
                  오시는길
                </Link>
              </li>
            </ol>
          </li>
          <li className={styles.right} id="1">
            <div>
              <Link to="/care/1" id={styles.menu}>
                돌봄서비스
              </Link>
            </div>
            <ol className={styles.depth2} id="1">
              <li>
                <Link to="/care/1" id="1">
                  장애인활동지원서비스
                </Link>
              </li>
              <li>
                <Link to="/care/2" id="1">
                  노인장기요양서비스
                </Link>
              </li>
              <li>
                <Link to="/care/3" id="1">
                  가사간병방문지원서비스
                </Link>
              </li>
              <li>
                <Link to="/care/4" id="1">
                  돌봄SOS서비스
                </Link>
              </li>
              <li>
                <Link to="/care/5" id="1">
                  서비스 상담 문의
                </Link>
              </li>
            </ol>
          </li>
          <li className={styles.left} id="2">
            <div>
              <Link to="/notice" id={styles.menu}>
                공지사항
              </Link>
            </div>
            <ol className={styles.depth2} id="2">
              <li>
                <Link to="/notice" id="3">
                  공지사항
                </Link>
              </li>
            </ol>
          </li>

          <li className={styles.right} id="3">
            <div>
              <Link to="/data" id={styles.menu}>
                자료실
              </Link>
            </div>

            <ol className={styles.depth2} id="3">
              <li>
                <Link to="/data" id="3">
                  일반자료실
                </Link>
              </li>
              <li>
                <Link to="/photo" id="3">
                  포토갤러리
                </Link>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Site;
