import styles from "./Nav.module.css";
import NavHeader from "./NavHeader";
import Container from "./Container";

function Nav() {
  return (
    <div className={styles.Nav}>
      <NavHeader />
      <Container />
    </div>
  );
}

export default Nav;
