import React from "react";
import { createRoot } from "react-dom/client";
import { hydrateRoot } from "react-dom/client";
import Main from "./Main";

const container = document.getElementById("root");
const root = createRoot(container);

if (container.hasChildNodes()) {
  const hydrate = hydrateRoot(container, <Main />);
} else {
  root.render(<Main />);
}
