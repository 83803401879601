import styles from "./DataPages.module.css";
import SubTitle from "../components/SubTitle";
import Item from "../components/Item";
import { Link } from "react-router-dom";
import Location from "../components/Location";
import SubTel from "../components/SubTel";
import { useState, useEffect, useCallback } from "react";
import useAsync from "../hooks/useAsync";
import { getDatas } from "../Api";
import { useParams } from "react-router-dom";
const LIMIT = 6;

function DataPages() {
  const no = useParams().no;
  const name = "자료실";
  const value =
    "중구행복돌봄센터는 함께하는 가치로 사람중심, 상호신뢰와 배려, 같이 삶을 실현하는 공동체입니다.";
  const menu = "자료실";
  const submenu = "일반자료실";
  const linked = "/data";
  const [order, setOrder] = useState("createdAt");
  const [isLoading, loadingError, getNoticessAsync] = useAsync(getDatas);
  const [items, setItems] = useState([]);

  const handleLoad = useCallback(
    async (options) => {
      const result = await getNoticessAsync(options);
      //   console.log("확인");
      if (!result) return;

      const datas = result;
      //   console.log(datas);
      if (options.offset === 0) {
        setItems(datas);
      } else {
        setItems((prevItems) => [...prevItems, ...datas]);
      }
      setOrder("createdAt");
    },
    [getNoticessAsync]
  );

  useEffect(() => {
    handleLoad({ order, offset: 0, limit: LIMIT });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //   console.log(items);

  return (
    <div className={styles.Data}>
      <div className={styles.left}>
        <SubTitle name={name} value={value} />

        <ol className={styles.depth2} id="0">
          <li>
            <Link to="/data" id={styles.menu}>
              일반자료실
            </Link>
          </li>
          <li>
            <Link to="/photo" id={styles.menu2}>
              포토갤러리
            </Link>
          </li>
        </ol>
        <SubTel height="1" />
      </div>
      <div id={styles.right}>
        <Location menu={menu} submenu={submenu} link={linked} />
        <h2>{submenu}</h2>
        <div className={styles.Container}>
          {!isLoading && <Item items={items} no={no} order={linked} />}
        </div>

        {loadingError?.message && <span>{loadingError.message}</span>}
      </div>
    </div>
  );
}

export default DataPages;
