import styles from "./SubTitle.module.css";

function SubTitle({ name, value }) {
  return (
    <div className={styles.SubTitle}>
      <h2 className={styles.k_title}>{name}</h2>
      <p className={styles.e_title}>{value}</p>
    </div>
  );
}

export default SubTitle;
